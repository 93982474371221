import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  ProgressBar,
} from "react-bootstrap";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { toast } from "react-toastify";
import { baseURL } from "../../../utils/constant";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import Loading from "../../../assets/img/dashbord/loading.gif";

const StepProgressBarRedux = () => {
  const { _id } = useParams();
  const campaignId = _id;
  // const { campaignId } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Local State
  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
    leadFields: [],
    isCSV: false,
    emailCheck: false,
    personCheck: false,
    companyCheck: false,
    voiceCallCheck: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMode, setViewMode] = useState("grid");
  const [showImportButton, setShowImportButton] = useState(true);
  const [crossIconVisible, setCrossIconVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  // Redux State
  const campaignData = useSelector((state) => state.dashboard.campaignData); // Fetch campaigns
  const isDashboardLoading = useSelector((state) => state.dashboard.isLoading); // Loading status

  // Fetch dashboard data on component mount
  useEffect(() => {
    if (!campaignData || campaignData.length === 0) {
      dispatch(fetchDashboardData({ source: ["campaign"], reload: false }));
    }
  }, [dispatch, campaignData]);

  // Update local state when the campaignData is fetched
  useEffect(() => {
    if (campaignData?.length > 0) {
      const currentCampaign = campaignData.find(
        (campaign) => campaign._id === campaignId
      );
      if (currentCampaign) {
        setIdData({
          campaignName: currentCampaign.name,
          status: currentCampaign.status,
          recordCount: currentCampaign.recordCount,
          leadFields: currentCampaign.leadFields,
          isCSV: currentCampaign.isCSV,
          emailCheck: currentCampaign.emailCheck,
          personCheck: currentCampaign.personCheck,
          companyCheck: currentCampaign.companyCheck,
          voiceCallCheck: currentCampaign.voiceCallCheck,
        });
        // setData(currentCampaign.qualifiedData || []);
        setData(currentCampaign.csvData || []);


      } else {
        toast.error("Campaign not found!", { position: "bottom-right" });
        navigate("/"); // Redirect if the campaign is not found
      }
    }
    setIsLoading(false);
  }, [campaignData, campaignId, navigate]);

  // const calculateProgress = (data) => {
  //   const steps = [
  //     { name: "Import CSV", completed: data.isCSV },
  //     { name: "Email Lookup", completed: data.emailCheck },
  //     { name: "Prospect Verification", completed: data.personCheck },
  //     { name: "Company Verification", completed: data.companyCheck },
  //     { name: "Voice Authentication", completed: data.voiceCallCheck },
  //   ];

  //   const completedSteps = steps.filter((step) => step.completed);
  //   const isFinalStep = steps.every((step) => step.completed);

  //   return isFinalStep
  //     ? [...steps, { name: "Completed", completed: true, finalStep: true }]
  //     : steps;
  // };


  // const calculateProgress = (data, csvData) => {
  //   if (!csvData || csvData.length === 0) return [];

  //   const totalEntries = csvData.length;

  //   const completedEmailCheck = csvData.filter(entry => entry.emailCheck === "true").length;
  //   const completedPersonCheck = csvData.filter(entry => entry.personCheck === "true").length;
  //   const completedCompanyCheck = csvData.filter(entry => entry.companyCheck === "true").length;
  //   const completedVoiceCallCheck = csvData.filter(entry => entry.voiceCallCheck === "true").length;

  //   const steps = [
  //     { name: "Import CSV", completed: data.isCSV },
  //     { name: "Email Lookup", completed: completedEmailCheck === totalEntries },
  //     { name: "Prospect Verification", completed: completedPersonCheck === totalEntries },
  //     { name: "Company Verification", completed: completedCompanyCheck === totalEntries },
  //     { name: "Voice Authentication", completed: completedVoiceCallCheck === totalEntries },
  //   ];

  //   const completedSteps = steps.filter(step => step.completed);
  //   const isFinalStep = steps.every(step => step.completed);

  //   return isFinalStep
  //     ? [...steps, { name: "Completed", completed: true, finalStep: true }]
  //     : steps;
  // };


  const calculateProgress = (data, csvData) => {
    if (!csvData || csvData.length === 0) return [];

    const totalEntries = csvData.length;

    const completedEmailCheck = csvData.filter(entry => entry.emailCheck === "true").length;
    const completedPersonCheck = csvData.filter(entry => entry.personCheck === "true").length;
    const completedCompanyCheck = csvData.filter(entry => entry.companyCheck === "true").length;
    const completedVoiceCallCheck = csvData.filter(entry => entry.voiceCallCheck === "true").length;

    const steps = [
      {
        name: "Import CSV",
        completed: data.isCSV,
        percentage: data.isCSV ? 100 : 0
      },
      {
        name: "Email Lookup",
        completed: completedEmailCheck === totalEntries,
        percentage: Math.round((completedEmailCheck / totalEntries) * 100)
      },
      {
        name: "Prospect Verification",
        completed: completedPersonCheck === totalEntries,
        percentage: Math.round((completedPersonCheck / totalEntries) * 100)
      },
      {
        name: "Company Verification",
        completed: completedCompanyCheck === totalEntries,
        percentage: Math.round((completedCompanyCheck / totalEntries) * 100)
      },
      {
        name: "Voice Authentication",
        completed: completedVoiceCallCheck === totalEntries,
        percentage: Math.round((completedVoiceCallCheck / totalEntries) * 100)
      }
    ];

    const isFinalStep = steps.every(step => step.completed);
    return isFinalStep
      ? [...steps, { name: "Completed", completed: true, finalStep: true }]
      : steps;
  };

  const steps = calculateProgress(IdData, Data);

  // const steps = calculateProgress(IdData);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowImportButton(false);
      setCrossIconVisible(true);
    }
  };

  const handleFileDeselect = () => {
    setSelectedFile(null);
    setShowImportButton(true);
    setCrossIconVisible(false);
  };

  if (isLoading || isDashboardLoading) {
    return <div className="d-flex justify-content-center align-items-center vh-100">
      <img src={Loading} alt="Loading" width={25} />
    </div>
  }

  return (
    <Container fluid>
      <div className="mb-3 my-3 ms-3">
        <Link
          to="/account/campaigns"
          className="text-decoration-none link-style-none text-black fs-6"
        >
          <span className="text-muted">Back to Campaigns</span>
        </Link>
      </div>
      <Row>
        <Col className="p-4">
          {/* {IdData.status === "New" && (
            <Col lg={12} className="mx-auto text-center">
              <Alert variant="warning">
                <span>
                  <FaExclamation className="text-warning me-2" /> Please check
                  the CSV file format <a href="#">here</a> before importing.
                </span>
              </Alert>
              {showImportButton && (
                <div className="text-center">
                  <Button variant="orange" className="mb-4">
                    Import CSV Document
                  </Button>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <p>or drop a file</p>
                </div>
              )}
              {crossIconVisible && selectedFile && (
                <div>
                  <p className="text-success cursor-pointer">
                    Selected File: {selectedFile.name}{" "}
                    <span onClick={handleFileDeselect}>&#10006;</span>
                  </p>
                  <Button variant="orange">Submit</Button>
                </div>
              )}
            </Col>
          )} */}

          {IdData.status === "Pending" && (
            <Col lg={12} className="mx-auto text-center">
              <p className="fs-5 text-orange">
                Sit back and relax. Your leads are getting verified.
              </p>
              <div className="progress-div d-flex justify-content-center">
                {/* <ul className="progress vertical py-5  px-4 vh-60">
                  {steps.map((step, index) => (
                    <li
                      key={index}
                      className={
                        step.completed
                          ? step.finalStep
                            ? "inactive final-step"
                            : "active"
                          : "inactive"
                      }
                    >
                      {step.completed ? (
                        <FaCheck className="checkmark" />
                      ) : (
                        <div className="loader-1">
                          <div className="ripple"></div>
                        </div>
                      )}
                      <span>{step.name}</span>
                   

<ProgressBar
  striped
  variant="warning"
  now={step.completed ? 100 : (step.name === "Import CSV" ? 100 : 60)}
  className="stepbar"
/>

                    </li>
                  ))}
                </ul> */}

                <ul className="progress vertical py-5 px-4 vh-60">
                  {steps.map((step, index) => (
                    <li
                      key={index}
                      className={step.completed ? (step.finalStep ? "inactive final-step" : "active") : "inactive"}
                    >
                      {step.completed ? (
                        <FaCheck className="checkmark" />
                      ) : (
                        <div className="loader-1">
                          <div className="ripple"></div>
                        </div>
                      )}
                      <span className="step-name ">{step.name}</span>
                      <ProgressBar
                        striped
                        variant="warning"
                        now={step.percentage}
                        // label={`${step.percentage}%`} 
                        className={step.percentage !== 100 ? "stepbar  stepbar-warning" : " stepbar"}

                      />
                      <span> {step.percentage}%</span>
                    </li>
                  ))}
                </ul>

              </div>
            </Col>
          )}


        </Col>
      </Row>
    </Container>
  );
};

export default StepProgressBarRedux;
