import React from "react";
import { Outlet } from "react-router-dom";

const FullScreenLayout = () => {
  return (
    <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Outlet />
    </div>
  );
};

export default FullScreenLayout;
