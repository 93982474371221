import React from "react";
import { Outlet } from "react-router-dom";
import NewSidebar2 from "../components/NewSidebar2";
import SubNav from "../components/subNav";

const SidebarLayout = () => {
  return (
    <>
    <div className="d-flex private-layout flex-column vh-100">
      <SubNav className="sub-nav w-100" />
      <div className="d-flex flex-grow-1 overflow-hidden">
        <NewSidebar2 className="overflow-auto" />
        <div className="content flex-grow-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  </>
  );
};

export default SidebarLayout;