import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Myaccount from "../../../components/account/Myaccount";

const Profile = () => {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${baseURL}dashboard/getMergedData`, {
  //         headers,
  //       });

  //       const userData = response.data.data.find(
  //         (item) => item.source === "user"
  //       );

  //       if (userData) {
  //         const {
  //           credits,
  //           firstName,
  //           lastName,
  //           planExpiresOn,
  //           planName,
  //           email,
  //           status,
  //           address,
  //         } = userData;

  //         setUser({ credits, firstName, lastName, planExpiresOn, planName, email });
  //         setFirstName(firstName);
  //         setLastName(lastName);
  //         setEmail(email);

  //         if (status !== "Active") setShowAlert(true);
  //         if (new Date(planExpiresOn) < new Date()) setExpireAlert(true);

  //         if (address) {
  //           setAddress1(address.address_line_one || "");
  //           setAddress2(address.address_line_two || "");
  //           setCity(address.city || "");
  //           setState(address.state || "");
  //           setCountry(address.country || "");
  //           setPincode(address.postal || "");
  //         }
  //       }
  //     } catch (error) {
  //       if (error.response?.status === 401) {
  //         navigate("/");
  //       } else {
  //         toast.error("Failed to fetch data", { position: "bottom-right" });
  //       }
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <>
      <Myaccount />
    </>
  );
};

export default Profile;
