import React from "react";
import { Button } from "react-bootstrap";
import { PiCoinsLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
function formatNumber(value) {
  if (value < 1000) {
    return value.toString();
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
}

const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}


const Credits = ({ dashboardData }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="card-header p-3 pb-0">
        <h6 className="mb-0">Credits</h6>
        <div className="d-flex">
          <div className="p text-sm font-weight-bold mb-0 ">Available</div>
        </div>
        <hr className="horizontal dark mb-0" />
      </div>
      <div className="text-start">
        <div className="d-flex justify-content-center">
          <div className="mt-3 d-flex flex-row">
            <h3 className="fw-bold text-muted">
              <PiCoinsLight className="me-1 sq-db-icon" size={"40px"} />
              {getFormattedDate(dashboardData.user.planExpiresOn) < getFormattedDate(new Date()) || dashboardData.user.planExpiresOn < new Date() ? 0 : formatNumber(dashboardData.credits)}

            </h3>
          </div>
        </div>
        <div className="d-flex justify-content-end my-3">
          {getFormattedDate(dashboardData.user.planExpiresOn) < getFormattedDate(new Date()) || dashboardData.user.planExpiresOn < new Date() ? (
            <Button
              className="border-0 bg-gradient-warning p-2"
              onClick={() => navigate("/account/billing/plans")}
            >
              Upgrade Now
            </Button>
          ) : (
            <Button
              className="border-0 bg-gradient-warning p-2"
              onClick={() => navigate("/account/addcredits")}
            >
              Refill Now
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Credits;
