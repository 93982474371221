import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const CampaignPieChart = ({ data }) => {
  // Provide fallback data if data is missing
  const { labels = ["No Data"], series = [0] } = data;

  // Prepare data for amCharts Pie Chart
  const chartData = labels.map((label, index) => ({
    category: label,
    value: series[index] || 0,
  }));


  // Color palette from your original code (match with legend items)
  const colorMap = {
    "Complete": "#4CAF50",
    "Pending": "#FFC107",
    "New": "#FF9800",
  };  
  

  useEffect(() => {
    // Create root element
    let root = am5.Root.new("piechartdiv");
    root.autoResize = true;

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50), // Doughnut chart
      })
    );

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
      })
    );

    // Set data
    series.data.setAll(chartData);

    const colors = ["#4CAF50", "#FF9800", "#FFC107"];

// Loop through the series' data items
series.dataItems.forEach((dataItem, index) => {
    // Apply color from the colors array based on index
    dataItem.set("fill", colors[index % colors.length]);
    dataItem.set("stroke", colors[index % colors.length]);
});

// Optionally, if you want to set other properties like visibility:
series.labels.template.setAll({ visible: false });
series.ticks.template.setAll({ visible: false });
series.labels.template.set("forceHidden", true);
series.ticks.template.set("forceHidden", true);
    

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
      })
    );

    // Set legend data
    legend.data.setAll(series.dataItems);

    // Animate the chart
    series.appear(1000, 100);

    // Cleanup function
    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <div
      className="mt-3"
      id="piechartdiv"
      style={{ width: "100%", height: "500px", margin: "0 auto" }}
    ></div>
  );
};

export default CampaignPieChart;
