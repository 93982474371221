import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { getCookie } from "../../../utils/auth";
import { toast } from "react-toastify";
import { AiTwotoneDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import Loading from "../../../assets/img/dashbord/loading.gif";
const ImportLeads = () => {
  const { state } = useLocation();
  const { campaignId } = state || {};

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
const dispatch = useDispatch();
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}campaign/getCampaignByCampaignId/${campaignId}`,
        { headers }
      );

      const csvData = response.data?.csvData || [];
      // Add serial number to each row
      const enrichedData = csvData.map((item, index) => ({
        srNo: (index + 1).toString().padStart(2, "0"), // Format: 01, 02, etc.
        ...item,
      }));

      // Set Data
      setData(enrichedData);

      // Generate Columns
      if (enrichedData.length > 0) {
        const dynamicColumns = [
          { name: "Sr No.", selector: (row) => row.srNo, sortable: false },
          ...Object.keys(enrichedData[0])
            .filter((key) => key !== "srNo")
            .map((key) => ({
              name: key
                .replace(/([A-Z])/g, " $1") // Split camelCase into words
                .replace(/^./, (str) => str.toUpperCase()), // Capitalize
              selector: (row) => row[key],
              sortable: true,
            })),
         
        ];
        setColumns(dynamicColumns);
      } else {
        setColumns([]);
      }
    } catch (error) {
      toast.error("Failed to fetch data. Please try again.", { position: "bottom-right" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (srNo) => {
    const filteredData = data.filter((row) => row.srNo !== srNo);
    setData(filteredData);
    toast.success("Lead deleted successfully!");
  };

  useEffect(() => {
    if (campaignId) {
      fetchData();
    }
  }, [campaignId]);


  const handlenext = ()=> {
        dispatch(fetchDashboardData({ source: ["campaign"], reload: false})).then(() => {
          navigate(`/account/stepprogressbar/${campaignId}`);
        });
  }

  return (
    <div className="import-leads-container">
    {isLoading ? (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <img src={Loading} alt="Loading" width={25} />
      </div>
    ) : (
      <>
        <h3 className="container-fluid my-4">
          <span className="title">{data.length}</span> Leads Imported
        </h3>

        <div className="text-end start-campaign-btn-cnt">
          <button className="start-campaign-btn btn bg-gradient-warning" onClick={handlenext}>
            Submit Campaign
          </button>
        </div>

        {data.length > 0 ? (
          <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={customStyles}
            className="import-leads-table"
          />
        ) : (
          <p className="text-center">No Data Found</p>
        )}
      </>
    )}
  </div>
  );
};

export default ImportLeads;

// Custom Styles for DataTable
const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // Overwrite row height
    },
  },
  headCells: {
    style: {
      backgroundColor: "#f5f5f5",
      fontWeight: "bold",
      fontFamily: "Proxima Nova",
    },
  },
};
