import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import NewCampaign from "../views/account/CreateCampaign/NewCampaign";
import Uploadcsv from "../views/account/CreateCampaign/Uploadcsv";
// Import Sidebar Layout
import SidebarLayout from "../layouts/SidebarLayout";
import PublicLayout from "../layouts/PublicLayout"; // New Public Layout

// Account Pages
import Profile from "../views/account/Profile/Profile";
import Allinvoice from "../views/account/Billing/Allinvoice";
import Plans from "../views/account/Billing/Plans";
import CampaignType from "../views/account/CreateCampaign/CampaginTyle";
// import Campaign from "../views/account/Campaign/CampaignView";
// import  from "../views/account/Campaign/CampaignView";
import AddCredits from "../views/account/AddCredits";
import ShowHeaders from "../views/account/CreateCampaign/ShowHeaders";
import EnrichLeadsData from "../views/account/CreateCampaign/EnrichLeadsData";
import ImportLeads from "../views/account/CreateCampaign/ImportLeads";

// Public Pages
import App from "../App";
import Login from "../views/pages/Login";
import ResetPassword from "../views/pages/ResetPassword";
import ForgotPassword from "../views/pages/forgotPassword";
import Register from "../views/pages/Register";
import Contact from "../views/pages/Contact";
import Cancellation from "../views/pages/Cancellation";
import Support from "../views/pages/Support";
import Termsofservice from "../views/pages/Termsofservice";
import Privacypolicy from "../views/pages/Privacypolicy";
import FaqPage from "../views/pages/FaqPage";

// FAQ Pages
import GeneralInfo from "../views/pages/faq/GeneralInfo";
import GettingStart from "../views/pages/faq/GettingStart";
import AppFeat from "../views/pages/faq/AppFeat";
import CampaignManagement from "../views/pages/faq/CampaignManagement";
import AccountManagement from "../views/pages/faq/AccountManagement";
import PlanAndBilling from "../views/pages/faq/PlanAndBilling";
import SecurityAndPrivacy from "../views/pages/faq/SecurityAndPrivacy";
import Troubleshooting from "../views/pages/faq/Troubleshooting";
import HelpAndSupport from "../views/pages/faq/HelpAndSupport";

// Payment Pages
// import NewCampaign from "../views/account/CreateCampaign/NewCampaign";
// import Uploadcsv from "../views/account/CreateCampaign/Uploadcsv";
import StripeSuccess from "../views/account/Payment/StripeSuccess";
import Settings from "../components/account/Settings";
import DashboardRedux from "../views/account/Dashboard/DashboardRedux";
import PastCampaignRedux from "../views/account/PastCampaign/CampaignListRedux";
import BillingRedux from "../views/account/Billing/BillingRedux";
import StripeFailed from "../views/account/Payment/StripeFailed";
import CompleteStatus from "../views/account/Campaign/CompleteStatusRedux";
import StepProgressBarRedux from "../views/account/Campaign/StepProgressBarRedux";
import { isLogin } from "../utils/auth";
import FullScreenLayout from "../layouts/FullScreenLayout";
import StripeSuccessTest from "../views/account/Payment/StripeSucessTest";
import EmailVerify from "../views/pages/EmailVerify";

// Private Route Component
const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  return token ? (
    <SidebarLayout>
      <Outlet />
    </SidebarLayout>
  ) : (
    <Navigate replace to="/login" />
  );
};

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return !!token;
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const result = await isLogin(navigate); // Asynchronous login check
      setIsAuthenticated(result);
    };

    checkLoginStatus();
  }, []);

  return (
    <>
      <div className="main-content">
        <Routes>
          <Route element={<FullScreenLayout />}>
            <Route path="/stripe-success" element={<StripeSuccess />} />
            <Route path="/test-stripe-success" element={<StripeSuccessTest />} />
            <Route path="/stripe-cancel" element={<StripeFailed />} />
            <Route path="/auth/verify-email/:token" element={<EmailVerify />} />
          </Route>
          {/* Public Routes with Header, Outlet, Footer */}
          <Route element={<PublicLayout />}>
            <Route path="/" element={<App />} />
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/register"
              element={<Register setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/login/forgotpassword" element={<ForgotPassword />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cancellation" element={<Cancellation />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/terms-of-service" element={<Termsofservice />} />
            <Route path="/support" element={<Support />} />
            <Route path="/faq" element={<FaqPage />} />


            {/* FAQ Pages */}
            <Route path="/faq/general-information" element={<GeneralInfo />} />
            <Route path="/faq/getting-started" element={<GettingStart />} />
            <Route path="/faq/app-features" element={<AppFeat />} />
            <Route
              path="/faq/campaign-management"
              element={<CampaignManagement />}
            />
            <Route
              path="/faq/account-management"
              element={<AccountManagement />}
            />
            <Route path="/faq/plans-and-billing" element={<PlanAndBilling />} />
            <Route
              path="/faq/security-and-privacy"
              element={<SecurityAndPrivacy />}
            />
            <Route path="/faq/troubleshooting" element={<Troubleshooting />} />
            <Route path="/faq/help-and-support" element={<HelpAndSupport />} />
          </Route>

          {/* Authenticated Routes */}
          <Route
            path="/account/*"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <SidebarLayout />
              </PrivateRoute>
            }
          >
            {/* Account Sub-Routes */}
            <Route path="dashboard" element={<DashboardRedux />} />
            <Route path="campaigns" element={<PastCampaignRedux />} />

            <Route path="profile" element={<Profile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="billing" element={<BillingRedux />} />
            <Route path="billing/invoices/:_id" element={<Allinvoice />} />
            <Route path="billing/plans" element={<Plans />} />
            {/* <Route path="createcampaign" element={<CreateCampaign />} /> */}
            {/* this is new Campaign */}
            <Route path="createcampaign" element={<NewCampaign />} />
            <Route path="completeStatus/:_id" element={<CompleteStatus />} />
            <Route path="uploadcsv/:_id" element={<Uploadcsv />} />
            <Route path="showheaders/:_id" element={<ShowHeaders />} />
            <Route path="EnrichLeadsData/:_id" element={<EnrichLeadsData />} />
            <Route path="importLeads/:_id" element={<ImportLeads />} />
            {/* 
            <Route
              path="create-campaign/create-type"
              element={<CampaignType />}
            /> */}
            {/* <Route path="campaign/:_id" element={<Campaign />} /> */}
            <Route
              path="stepprogressbar/:_id"
              element={<StepProgressBarRedux />}
            />
            {/* <Route path="stepprogressbar/:_id" element={<StepProgressBar />} /> */}
            <Route path="addcredits" element={<AddCredits />} />
          </Route>
        </Routes>
      </div>

      <ToastContainer />
    </>
  );
};

export default AppRoutes;
