import Container from "react-bootstrap/Container";
import React, { Component } from "react";
import { Row, Col, Button, Pagination, Dropdown, Modal } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { saveAs } from "file-saver";
import { FaList } from "react-icons/fa";
import { FaCheck, FaExclamation } from "react-icons/fa6";
import Swal from "sweetalert2";
import { FiDownload } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { LuBadgeCheck, LuX } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import { PiWarningLight } from "react-icons/pi";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


function CompleteStatus() {
  const { _id } = useParams(); // Get the campaign ID from the route
  const dispatch = useDispatch();

  

  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
    csvData: "",
    qualifiedData: [],
    leadFields: [],
    isCSV: false,
    emailCheck: false,
    personCheck: false,
    companyCheck: false,
    voiceCallCheck: false,

  });
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileInputDisabled, setFileInputDisabled] = useState(true);
  const [viewMode, setViewMode] = useState("grid");
  const [show, setShow] = useState(true);
  const [statusFilter, setStatusFilter] = useState("All"); // New state for filter

  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const campaignData = useSelector((state) => state.dashboard.campaignData); // Fetch campaigns
  const isDashboardLoading = useSelector((state) => state.dashboard.isLoading); // Loading status

  useEffect(() => {
    if (!campaignData || campaignData.length === 0) {
      dispatch(fetchDashboardData( { source: ["campaign"], reload: false}));
    }
  }, [dispatch, campaignData]);

  useEffect(() => {
    if (campaignData?.length > 0) {
      const currentCampaign = campaignData.find((campaign) => campaign._id === _id);
      if (currentCampaign) {
        const verifiedLeadsCount =
        currentCampaign.qualifiedData?.filter(
          (lead) => lead.status === "Verified"
        ).length || 0;
      const notVerifiedLeadsCount =
        currentCampaign.qualifiedData?.filter(
          (lead) => lead.status !== "Verified"
        ).length || 0;


        setIdData({
          campaignName: currentCampaign.campaignName,
          status: currentCampaign.status,
          recordCount: currentCampaign.recordCount,
          verifiedLeadsCount,
          notVerifiedLeadsCount,
          csvData: currentCampaign.csvData,
          qualifiedData: currentCampaign.qualifiedData,
          leadFields: currentCampaign.leadFields,
          isCSV: currentCampaign.isCSV,
          emailCheck: currentCampaign.emailCheck,
          personCheck: currentCampaign.personCheck,
          companyCheck: currentCampaign.companyCheck,
          voiceCallCheck: currentCampaign.voiceCallCheck,
        });

        setData(currentCampaign.qualifiedData || []);
      } else {
        toast.error("Campaign not found!");
        navigate("/"); // Redirect if the campaign is not found
      }
    }
    setIsLoading(false);
  }, [campaignData, _id, navigate]);
 

  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleRowClick1 = (rowData) => {
    setSelectedRow(rowData);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const filteredData = Data && Array.isArray(Data)
    ? Data.filter((item) => {
      const fullName = `${item.first_name} ${item.last_name} ${item.email} `.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    })
    : [];

  const statusFilteredData = filteredData.filter((item) => {
    if (statusFilter === "All") return true; // No filtering when "All" is selected
    return item.status === statusFilter; // Filter by status when not "All"
  });

  const handleEntriesPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setSelectedEntriesPerPage(selectedValue);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * selectedEntriesPerPage;
  const endIndex = startIndex + selectedEntriesPerPage;

  const currentData = statusFilteredData.slice(startIndex, endIndex);
  const [openRows, setOpenRows] = useState({});
  const handleRowClick = (index) => {
    setOpenRows((prev) => {
      const updatedRows = { ...prev, [index]: !prev[index] };
      if (updatedRows[index]) {
        // Ensure only one row is open at a time
        Object.keys(updatedRows).forEach((key) => {
          if (parseInt(key, 10) !== index) {
            updatedRows[key] = false;
          }
        });
      }
      return updatedRows;
    });
  };


  useEffect(() => {
    setFileInputDisabled(false);
  }, [Data]);

  const fileInputRef = useRef(null);

  const statusMap = {
    Verified: "Verified",
    Failed: "Failed",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / selectedEntriesPerPage);
    const maxVisiblePages = 5;

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage and endPage if endPage is too close to totalPages
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {currentPage > 1 && (
          <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
            &laquo;
          </Pagination.Item>
        )}

        {pageNumbers.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
            className="bg-white"
          >
            {number}
          </Pagination.Item>
        ))}

        {currentPage < totalPages && (
          <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
            &raquo;
          </Pagination.Item>
        )}
      </Pagination>
    );
  };



  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "First Name",
      "Last Name",
      "Job Title",
      "Email",
      "Phone Number",
      "Company Name",
      "status",
    ];
    csvRows.push(headers.join(","));

    currentData.forEach((item) => {
      const row = [
        item.first_name,
        item.last_name,
        item.jobTitle,
        item.email,
        item.phone_number,
        item.company_name,
        item.status,
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    saveAs(blob, "contacts.csv");
  };


  if (isLoading || isDashboardLoading) {
    return <div>Loading...</div>;
  }



  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="" width={25} />
        </div>
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col className="p-4">
                <Row>
                  <Col lg={12} className="mx-auto">
                    <div className="mb-3">
                      <Link
                        to="/account/campaigns"
                        className="text-decoration-none link-style-none text-black fs-6"
                      >
                        <span className="text-muted">Back to Campaigns</span>
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <Row className="w-100">
                        <Col className="d-flex align-items-center">
                          <h4 className="fw-bold me-3">
                          
                            {IdData?.campaignName?.charAt(0).toUpperCase() + IdData?.campaignName?.slice(1) || 'No Campaign Name'}

                          </h4>

                          <span
                            className={
                              IdData.status === "Pending"
                                ? "badge badge-warning"
                                : IdData.status === "New"
                                  ? "badge badge-info"
                                  : "badge badge-success"
                            }
                          >
                            {IdData.status === "Pending"
                              ? "In Progress"
                              : IdData.status}
                          </span>
                        </Col>
                        <Col className="d-flex flex-column align-items-end px-0">
  {/* Total Leads Count */}
  <div className="fs-4 fw-bold text-orange">
    {IdData.recordCount} Leads
  </div>

  {/* Verified / Not Verified Count */} 
  <div className="fs-5">
    <span
      className="text-success me-1"
      title={`${IdData.verifiedLeadsCount} Verified Leads`}
    >
      {IdData.verifiedLeadsCount} Verified
    </span>
    /
    <span
      className="text-danger ms-1"
      title={`${IdData.notVerifiedLeadsCount} Unverified Leads`}
    > 
      {IdData.notVerifiedLeadsCount} Failed
    </span>
  </div>
</Col>

                      </Row>

                    </div>
                    {/* Headers Fileds and Download */}
                    <Row className="py-1">
                      <Col>
                        <div className="px-2 py-3 text-dark fs-6">
                          {" "}
                          Headers{" "}
                        </div>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-start py-0">
                      <Col>
                        {IdData.leadFields && IdData.leadFields.length > 0
                          ? IdData.leadFields.map((field) => (
                            <Badge
                              className=" badge badge-sm badge-success me-2 px-3 py-2 rounded-5 text-light"
                              key={field._id}
                            >
                              {field.label}
                            </Badge>
                          ))
                          : null}
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end">
                      {IdData.status === "Complete" ? (
                        <Button
                          className="mt-2"
                          style={{ fontSize: "medium" }}
                          variant="btn bg-gradient-warning"
                          onClick={downloadCSV}
                        >
                          <FiDownload className="rotate-icon me-1" />
                          Export CSV
                        </Button>
                      ) : null}
                    </div>

                    <hr className="mb-4" />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} className="mx-auto">
                    {IdData.status === "Complete" ? (
                      <>
                        <Row className="me-auto">
                          <Row className="m-0 p-0">
                            <Col lg={12}>
                              {/* {show && (
                                <Alert
                                  variant="warning"
                                  onClose={() => setShow(false)}
                                  dismissible
                                >
                                  <Icon.ExclamationTriangleFill className="text-warning me-2" />
                                  <span className="me-2">
                                    Do you want to enrich the data?
                                  </span>
                                  <Button
                                    className="me-2"
                                    style={{ fontSize: "small" }}
                                    variant="btn bg-gradient-warning"
                                    onClick={downloadCSV}
                                  >
                                    Yes
                                  </Button>
                                </Alert>
                              )} */}
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} className="my-2  ">
                              <h4>Your contacts</h4>
                            </Col>
                          </Row>
                          <Row className="align-items-center justify-content-between py-2">
                            {/* Search Input */}
                            <Col lg={6} className="d-flex align-items-center justify-content-between">
                              {/* Search Input */}
                              <Form.Group className="w-70 me-3 position-relative">
                                <Form.Control
                                  type="text"
                                  placeholder="Search..."
                                  value={searchQuery}
                                  onChange={(e) => handleSearch(e.target.value)}
                                  className="ps-5 "
                                />
                                <GoSearch className="position-absolute top-50 translate-middle-y ms-2 text-muted" />
                              </Form.Group>

                              {/* Entries per Page Selection */}
                              <Form.Select
                                className="w-25 me-3"
                                value={selectedEntriesPerPage}
                                onChange={(e) => setSelectedEntriesPerPage(parseInt(e.target.value, 10))}
                              >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                              </Form.Select>

                              {/* Status Filter Dropdown */}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  id="status-filter-dropdown"
                                  className="custom-dropdown-toggle"
                                >
                                  {statusFilter === "All" ? "Lead Select Status" : statusMap[statusFilter] || statusFilter}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => setStatusFilter("All")}>All</Dropdown.Item>
                                  {Object.entries(statusMap).map(([key, label]) => (
                                    <Dropdown.Item key={key} onClick={() => setStatusFilter(key)}>
                                      {label}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>


                            {/* List and Grid Icons */}
                            <Col lg={3} className="d-flex justify-content-end">
                              {/* List View Icon */}
                              <div
                                className={`icon1 ${viewMode === "list" ? "active1" : ""
                                  }`}
                                onClick={() => setViewMode("list")}
                              >
                                <FaList
                                  size={20}
                                  color={
                                    viewMode === "list" ? "#3966EF" : "#5f5f5f"
                                  }
                                />
                              </div>

                              {/* Grid View Icon */}
                              <div
                                className={`icon1 ms-3 ${viewMode === "grid" ? "active1" : ""
                                  }`}
                                onClick={() => setViewMode("grid")}
                              >
                                <TfiLayoutGrid3
                                  size={20}
                                  color={
                                    viewMode === "grid" ? "#6841E0" : "#5f5f5f"
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </Row>
                        <Row className="mt-4">
                          {currentData && currentData.length > 0 ? (
                            viewMode === "list" ? (
                              <TableContainer className="bg-white">
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Status</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Job Title</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Phone Number</TableCell>
                                      <TableCell>Company</TableCell>
                                      <TableCell>Industry</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {currentData.map((item, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow hover onClick={() => handleRowClick(index)}>
                                          <TableCell>
                                            {item.status === "Pending" ? (
                                              <PiWarningLight className="text-warning" />
                                            ) : item.status === "Verified" ? (
                                              <LuBadgeCheck className="text-success" />
                                            ) : item.status === "Failed" ? (
                                              <RxCrossCircled className="text-danger" />
                                            ) : (
                                              <PiWarningLight className="text-warning" />
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {item.first_name || "N/A"} {item.last_name || "N/A"}
                                          </TableCell>
                                          <TableCell>{item.job_title || "N/A"}</TableCell>
                                          <TableCell>{item.email || "N/A"}</TableCell>
                                          <TableCell>{item.phone_number || "N/A"}</TableCell>
                                          <TableCell>{item.company_name || "N/A"}</TableCell>
                                          <TableCell>{item.industry || "N/A"}</TableCell>
                                        </TableRow>

                                        {/* Expandable Row with Detailed Info */}
                                        <TableRow>
                                          <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                              <div style={{ padding: '16px' }}>
                                                <Row>
                                                  {Object.entries(item).map(([key, value], index) => {
                                                    // Capitalize the first letter of each word in the key
                                                    const formattedKey = key
                                                      .replace(/_/g, " ") // Replace underscores with spaces
                                                      .replace(/^./, (char) => char.toUpperCase()); // Capitalize first letter

                                                    return (
                                                      <Col md={4} key={index} className="mb-2">
                                                        <p>
                                                          <strong>{formattedKey}:</strong> {value || "N/A"}
                                                        </p>
                                                      </Col>
                                                    );
                                                  })}
                                                </Row>
                                              </div>
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>

                                      </React.Fragment>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              currentData.map((item, index) => (
                                <Col lg={4} key={index} className="mb-3">
                                  <div className="p-3 bg-white rounded shadow-sm" onClick={() => handleRowClick1(item)} style={{ cursor: "pointer" }}>
                                    {/* Header with Name and Status */}
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                      <h6 className="fw-semibold mb-0">
                                        {item.first_name || "N/A"} {item.last_name || "N/A"}
                                      </h6>
                                      <div>
                                        {item.status === "Pending" && (
                                          <PiWarningLight className="text-warning" />
                                        )}
                                        {item.status === "Verified" && (
                                          <LuBadgeCheck className="text-success" />
                                        )}
                                        {item.status === "Failed" && (
                                          <RxCrossCircled className="text-danger" />
                                        )}
                                      </div>
                                    </div>

                                    {/* Details Section */}
                                    <div className="mb-2">
                                      <Row className="border-top">
                                        <Col lg={4} md={4} xl={4} className="border-end">
                                          <div>
                                            <p className="mb-1 text-muted" style={{ fontSize: "14px" }}>
                                              <strong>Email:</strong>
                                            </p>
                                          </div>
                                          <div>
                                            <p className="mb-1 text-muted" style={{ fontSize: "14px" }}>
                                              <strong>Company:</strong>
                                            </p>
                                          </div>
                                          <div>
                                            <p className="mb-1 text-muted" style={{ fontSize: "14px" }}>
                                              <strong>Industry:</strong>
                                            </p>
                                          </div>
                                          <div>
                                            <p className="mb-1 text-muted" style={{ fontSize: "14px" }}>
                                              <strong>Job Title:</strong>
                                            </p>
                                          </div>
                                          <div>
                                            <p className="mb-0 text-muted" style={{ fontSize: "14px" }}>
                                              <strong>Contact:</strong>
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={8} md={8} xl={8}>
                                          <div className="truncate" title={item.email || "N/A"}>
                                            {item.email || "N/A"}
                                          </div>
                                          <div className="truncate" title={item.company_name || "N/A"}>
                                            {item.company_name || "N/A"}
                                          </div>
                                          <div className="truncate" title={item.industry || "N/A"}>
                                            {item.industry || "N/A"}
                                          </div>
                                          <div className="truncate" title={item.job_title || "N/A"}>
                                            {item.job_title || "N/A"}
                                          </div>
                                          <div className="truncate" title={item.phone_number || "N/A"}>
                                            {item.phone_number || "N/A"}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              ))
                            )
                          ) : (
                            <p>No contacts available.</p>
                          )}
                        </Row>
                        {/* Modal to display row data */}
                        <Modal show={showModal} onHide={handleClose} size="lg" centered>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {selectedRow ? `${selectedRow.first_name || ""} ${selectedRow.last_name || ""} Details`.trim() : "Lead Details"}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {selectedRow && (
                              <div className="p-3">
                                <Row>
                                  {Object.entries(selectedRow).map(([key, value], index) => {
                                    // Format key: replace underscores with spaces & capitalize the first letter
                                    const formattedKey = key
                                      .replace(/_/g, " ") // Replace underscores with spaces
                                      .replace(/^./, (char) => char.toUpperCase()); // Capitalize first letter

                                    return (
                                      <Col md={6} key={index} className="mb-2">
                                        <p>
                                          <strong className="fw-bold">{formattedKey}:</strong> {value || "N/A"}
                                        </p>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button className="bg-gradient-warning" onClick={handleClose}>Close</Button>
                          </Modal.Footer>
                        </Modal>





                        <div className="float-end py-2">{renderPagination()}</div>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};


export default CompleteStatus
