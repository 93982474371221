import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const StripeFailed = () => {
  const [counter, setCounter] = useState(5); // Initialize counter to 5 seconds
  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      navigate("/account/billing/plans"); // Redirect to the plans page
    }
  }, [counter, navigate]);

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <Row>
        <Col xs={12} className="text-center">
          <div className="mb-4 d-flex justify-content-center">
            <DotLottieReact
              src="https://lottie.host/ee97464f-e5cd-49ef-b29b-96f9a50fef4e/mFvDeUT9iy.lottie"
              autoplay
              style={{ width: "200px", height: "200px" }} // Set width & height here
            />
          </div>
          <h4 className="text-danger">Payment Failed</h4>
          <p className="text-secondary">
            Something went wrong with your payment. Please try again.
          </p>
          <p className="text-secondary">
            Redirecting to the plans page in <strong>{counter}</strong> seconds...
          </p>
          <div className="mt-4">
            <Button
              variant="danger"
              onClick={() => navigate("/account/billing/plans")}
            >
              Go to Plans
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StripeFailed;
