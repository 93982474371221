import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { baseURL } from '../../utils/constant';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { fetchDashboardData } from '../../redux/dashboardSlice';

const EmailVerify = () => {
    const { token } = useParams();  // ✅ Extract token from URL route
    const navigate = useNavigate();
    const [countDown, setCountDown] = useState(5);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) {
            console.error("❌ No token found in URL! API call will not be made.");
            return;
        }

        const verifyEmail = async () => {
            try {
                console.log(`Making API request to: ${baseURL}/auth/verify-email/${token}`);

                const response = await axios.get(`${baseURL}auth/verify-email/${token}`);

                if (response.data.status === 200) {
                    console.log('✅ Email verified successfully:', response.data.message);
                } else {
                    console.error('❌ Email verification failed:', response.data.error);
                }
            } catch (err) {
                console.error('⚠️ API Error:', err.response ? err.response.data.message : err.message);
            }
        };

        verifyEmail();
    }, [token]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((prev) => {
                if (prev === 1) {
                    clearInterval(interval);
                    console.log("⏳ Redirecting to dashboard...");

                    dispatch(fetchDashboardData({ 
                        source: ["allcount", "user", "campaign", "payment", "lead", "usage", "credits", "campaignCount", "paymentCount", "leadCount", "verifiedLeads", "notVerifiedLeads", "pieChart"], 
                        reload: false 
                    }));

                    navigate('/account/dashboard');
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [navigate, dispatch]);

    return (
        <Container className="d-flex justify-content-center align-items-center vh-70" style={{ minHeight: 'calc(100vh - 56px)' }}>
            <Row className="w-100 d-flex justify-content-center">
                <Col className="d-flex justify-content-center" xs={12} md={6}>
                    <Card className="p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <DotLottieReact
                                src="https://lottie.host/9d947fae-51f4-4091-8b42-b98cdc9394c5/jL2nDiib7G.lottie"
                                loop
                                autoplay
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </div>
                        <h3 className="text-center">Email Verification Successful</h3>
                        <p className='mb-0 text-center'>Your email has been successfully verified.</p>
                        <p className='text-center'>You will be redirected to the dashboard in {countDown} seconds.</p>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EmailVerify;