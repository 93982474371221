import axios from "axios";
import { baseURL } from "../../utils/constant";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setAuthentication } from "../../utils/auth";
import { toast } from "react-toastify";
import {
  IoLockClosedOutline,
  IoMailOutline,
  IoReturnDownBack,
} from "react-icons/io5";
import { FaEye, FaEyeSlash, FaLock, FaUserAlt } from "react-icons/fa"; // Import eye icons
import logo from "../../assets/img/images/logo/newbluelogo.png";
import { CiLock } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/dashboardSlice";
import { MdEmail } from "react-icons/md";
import spinglobe from '../../assets/img/spinglobe.gif';

export default function Register({ setisAuthenticated }) {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setfirstName(value);
    } else if (name === "lastName") {
      setlastName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      firstName,
      lastName,
      email,
      password,
      bearerToken: getCookie("bearerToken"),
    };

    axios
      .post(`${baseURL}auth/register`, payload)
      .then((res) => {
        setAuthentication(res.data.bearerToken);
        localStorage.setItem("token", res.data.bearerToken);
        dispatch(fetchDashboardData({ source: ["allcount", "user"], reload: false }));
        navigate("/account/dashboard");
        setisAuthenticated(true);
        
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <>
      <div className="d-flex align-items-center bg-login bg-orange-gradient">
        <Container fluid>
          <Row>
            <Col lg={4} className="vh-100 d-flex align-items-center mx-auto">
              <Col lg={12} className="p-0">
                <div className="card border-0 shadow bg-white rounded-5">
                  <div className="card-body p-5">
                    <div className="py-3">
                      <Container className="d-flex justify-content-center align-items-center">
                        <Link to="/">
                          <img src={logo} alt="Logo" className="img-fluid" style={{width: '250px'}} />
                        </Link>
                      </Container>
                    </div>
                    <h5 className="fw-bold mb-4">
                      Create your free account
                    </h5>
                    <Form onSubmit={handleSubmit}>
                      <Row className="mb-4">
                        {/* First Name Field */}
                        <Col md={6}>
                          <Form.Group className="position-relative">
                            <Form.Label className="label-top px-2">
                              First Name
                            </Form.Label>
                            <div className="input-group">
                              <span className="input-group-text ">
                                <FaUserAlt className="text-muted" />
                              </span>
                              <Form.Control
                                type="text"
                                className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                                name="firstName"
                                value={firstName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Form.Group>
                        </Col>

                        {/* Last Name Field */}
                        <Col md={6}>
                          <Form.Group className="position-relative">
                            <Form.Label className="label-top px-2">
                              Last Name
                            </Form.Label>
                            <div className="input-group">
                              <span className="input-group-text ">
                                <FaUserAlt className="text-muted" />
                              </span>
                              <Form.Control
                                type="text"
                                className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                                name="lastName"
                                value={lastName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        {/* Email Field */}
                        <Col md={6}>
                          <Form.Group className="position-relative">
                            <Form.Label className="label-top px-2">
                              Email
                            </Form.Label>
                            <div className="input-group">
                              <span className="input-group-text ">
                                <MdEmail className="text-muted" />
                              </span>
                              <Form.Control
                                type="email"
                                className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Form.Group>
                        </Col>

                        {/* Password Field */}
                        <Col md={6}>
                          <Form.Group className="position-relative">
                            <Form.Label className="label-top px-2">
                              Password
                            </Form.Label>
                            <div className="input-group password-group">
                              <span className="input-group-text ">
                                <FaLock className="text-muted" />
                              </span>
                              <Form.Control
                                type={passwordVisible ? "text" : "password"}
                                className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                              />
                              <button
                                type="button"
                                className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent password-icon"
                                onClick={handlePasswordToggle}
                              >
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                              </button>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Submit Button */}
                      <div className="d-grid gap-2 mb-4">
                        <Button
                          type="submit"
                          className="btn bg-gradient-warning rounded-3 py-3 border-0"
                        >
                          Sign up
                        </Button>
                      </div>
                    </Form>

                    <p className="text-center">
                      Already have an account?{" "}
                      <Link
                        to="/login"
                        className="text-decoration-none text-orange"
                      >
                        Sign in
                      </Link>
                    </p>
                    <div>
                      <span className="line">
                        <h2>
                          <span>Or</span>
                        </h2>
                      </span>
                      <div className="text-center">
                        <Link to="/" className="text-decoration-none text-dark">
                        <span className="me-1 spin-globe "><img src={spinglobe} alt="" className="img-fluid" /></span>
                          Visit Website
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
