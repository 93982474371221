import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../utils/auth";
import { baseURL } from "../utils/constant";

export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async ({ source = [], reload = false }, { getState, rejectWithValue }) => {
    const state = getState();
    const { dashboardData } = state.dashboard;

    console.debug("Current dashboardData:", dashboardData);

    if (source.length === 0 && dashboardData?.campaignCount > 0) {
      return dashboardData;
    }

    const bearerToken = getCookie("bearerToken");
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      source: source.join(","),
    };

    try {
      const response = await axios.get(`${baseURL}dashboard/getMergedData`, {
        headers,
      });

      console.log("API Response data:", response.data);

      const campaigns =
        response.data?.data?.find((item) => item.source === "campaign")?.data ||
        [];

      let totalLeads = 0;
      let verifiedLeads = 0;
      let notVerifiedLeads = 0;

      campaigns.forEach((campaign) => {
        if (campaign.status === "Complete") {
          totalLeads += campaign.qualifiedData?.length || 0;
          campaign.qualifiedData?.forEach((lead) => {
            if (lead.status === "Verified") {
              verifiedLeads += 1;
            } else {
              notVerifiedLeads += 1;
            }
          });
        }
      });

      console.log("Leads calculation:", { totalLeads, verifiedLeads, notVerifiedLeads });

      return {
        data: response.data?.data || [],
        totalLeads,
        verifiedLeads,
        notVerifiedLeads,
      };
    } catch (error) {
      console.error("Error fetching dashboard data:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: true,
    dashboardData: {
      campaignCount: 0,
      leadCount: 0,
      verifiedLeads: 0,
      notVerifiedLeads: 0,
      credits: 0,
      user: {
        firstName: "",
        lastName: "",
        planName: "",
        planExpiresOn: "",
      },
      pieChart: { labels: [], series: [] },
    },
    campaignData: [],
    leadData: [],
    paymentData: [],
    latestCampaigns: [],
    usageData: [],
    AllCount: [],
    error: null,
  },
  reducers: {
    resetDashboard: (state) => {
      console.debug("Resetting dashboard state");
      state.isLoading = true;
      state.dashboardData = {
        campaignCount: 0,
        leadCount: 0,
        verifiedLeads: 0,
        notVerifiedLeads: 0,
        credits: 0,
        user: {
          firstName: "",
          lastName: "",
          planName: "",
          planExpiresOn: "",
        },
        pieChart: { labels: [], series: [] },
      };
      state.campaignData = [];
      state.paymentData = [];
      state.latestCampaigns = [];
      state.usageData = [];
      state.AllCount = [];
      state.error = null;
    },
    editCampaign: (state, action) => {
      const { id, campaignName } = action.payload;
      const campaignIndex = state.campaignData.findIndex(campaign => campaign.id === id);
      if (campaignIndex !== -1) {
        state.campaignData[campaignIndex].campaignName = campaignName;
      }
    },
    deleteCampaign: (state, action) => {
      const { id } = action.payload;
      state.campaignData = state.campaignData.filter(campaign => campaign.id !== id);  
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        console.log("Fetching dashboard data...");
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        const data = Array.isArray(action.payload.data) ? action.payload.data : [];
        const { totalLeads, verifiedLeads, notVerifiedLeads } = action.payload;
        const reload = action.meta.arg.reload; // Get the reload flag

        let paymentData = null;
        let campaignData = null;
        let userData = null;
        let pieChartData = null;
        let usageData = null;
        let AllCount = [];

        data.forEach((item) => {
          switch (item.source) {
            case "payment":
              paymentData = item.data;
              break;
            case "campaign":
              campaignData = item;
              break;
            case "user":
              userData = item;
              break;
            case "pieChart":
              pieChartData = item;
              break;
            case "usage":
              usageData = item.data?.graphData || [];
              break;
            case "campaignCount":
            case "paymentCount":
            case "leadCount":
              AllCount.push({
                source: item.source,
                count: item.count || 0,
              });
              break;
            default:
              break;
          }
        });

        const paymentDataArray = paymentData
          ? Object.values(paymentData).filter((value) => typeof value === "object")
          : [];

        console.debug("Merging state with new data...");

        // 🚀 Prevent duplicate data by checking existing state
        const isDuplicate = (newData, existingData) =>
          JSON.stringify(newData) === JSON.stringify(existingData);

        state.dashboardData = {
          campaignCount: campaignData?.data?.length || state.dashboardData.campaignCount,
          leadCount: totalLeads || state.dashboardData.leadCount,
          verifiedLeads: verifiedLeads || state.dashboardData.verifiedLeads,
          notVerifiedLeads: notVerifiedLeads || state.dashboardData.notVerifiedLeads,
          credits: userData?.credits || state.dashboardData.credits,
          user: {
            firstName: userData?.firstName || state.dashboardData.user.firstName,
            lastName: userData?.lastName || state.dashboardData.user.lastName,
            planName: userData?.planName || state.dashboardData.user.planName,
            planExpiresOn: userData?.planExpiresOn || state.dashboardData.user.planExpiresOn,
            email: userData?.email || state.dashboardData.user.email,
            phone: userData?.phone || state.dashboardData.user.phone,
            address_line_one: userData?.address_line_one || state.dashboardData.user.address_line_one,
            address_line_two: userData?.address_line_two || state.dashboardData.user.address_line_two,
            emailVerified: userData?.emailVerified || state.dashboardData.user.emailVerified,
            city: userData?.city || state.dashboardData.user.city,
            state: userData?.state || state.dashboardData.user.state,
            country: userData?.country || state.dashboardData.user.country,
            pincode: userData?.postal || state.dashboardData.user.postal,
          },
          pieChart: {
            labels: pieChartData?.labels || state.dashboardData.pieChart.labels,
            series: pieChartData?.series || state.dashboardData.pieChart.series,
          },
        };

        // 🔹 Avoid duplicate paymentData
        state.paymentData = reload
          ? paymentDataArray
          : [...state.paymentData, ...paymentDataArray.filter((item) =>
            !state.paymentData.some((existing) => isDuplicate(existing, item))
          )];

        // 🔹 Avoid duplicate campaigns
        state.latestCampaigns = reload
          ? campaignData?.data.slice(0, 2) || []
          : [...state.latestCampaigns, ...(campaignData?.data.slice(0, 2) || []).filter((item) =>
            !state.latestCampaigns.some((existing) => isDuplicate(existing, item))
          )];

          const campaignList = Array.isArray(campaignData?.data) ? campaignData.data : [];
          console.log("campaignData:", campaignData);

          state.campaignData = reload
            ? campaignList
            : [
                ...state.campaignData.map((existing) => {
                  const updatedCampaign = campaignList.find((item) => item.id === existing.id);
                  return updatedCampaign ? { ...existing, ...updatedCampaign } : existing;
                }),
                ...campaignList.filter((item) =>
                  !state.campaignData.some((existing) => existing.id === item.id)
                ),
              ];
          
        

        // 🔹 Avoid duplicate usage data
        state.usageData = reload
          ? usageData || []
          : [...state.usageData, ...(Array.isArray(usageData) ? usageData.filter((item) =>
            !state.usageData.some((existing) => isDuplicate(existing, item))
          ) : [])];

        // 🔹 Avoid duplicate AllCount data
        state.AllCount = reload
          ? AllCount
          : [...state.AllCount, ...AllCount.filter((item) =>
            !state.AllCount.some((existing) => isDuplicate(existing, item))
          )];

        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        console.log("Error fetching dashboard data:", action.payload);
        state.error = action.payload;
      });
  },
});

export const { resetDashboard, editCampaign, deleteCampaign } = dashboardSlice.actions;
export default dashboardSlice.reducer;

