import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../../../assets/img/dashbord/loading.gif";

const Allinvoice = ({ paymentData }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(paymentData || []);

  useEffect(() => {
    if (!paymentData) {
      setFilteredData([]);
      return;
    }
  
    if (searchText === "") {
      setFilteredData(paymentData);
    } else {
      const filtered = paymentData.filter((payment) => {
        const searchTerm = searchText.toLowerCase();
  
        const amountString = `${payment.amount || ""} ${payment.currency || ""}`.toLowerCase();
        const dateString = payment.createdAt
          ? new Date(payment.createdAt).toLocaleDateString().toLowerCase()
          : "";
        const paymentType = (payment.type || "subscription").toLowerCase();
        const status = payment.status ? payment.status.toLowerCase() : "";
  
        const statusLabel =
          status === "succeeded"
            ? "completed"
            : status === "paid"
            ? "completed"
            : status === "pending"
            ? "pending"
            : status === "failed"
            ? "failed"
            : "unknown";
  
        return (
          (payment.customerName || "").toLowerCase().includes(searchTerm) ||
          status.includes(searchTerm) ||
          statusLabel.includes(searchTerm) ||
          dateString.includes(searchTerm) ||
          amountString.includes(searchTerm) ||
          paymentType.includes(searchTerm)
        );
      });
  
      setFilteredData(filtered);
    }
  }, [searchText, paymentData]);
  

  // Define table columns
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => (row && row.customerName) ? (index + 1).toString().padStart(2, "0") : "-",
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => 
        row.customerName 
          ? row.customerName.charAt(0).toUpperCase() + row.customerName.slice(1)
          : "-",
      sortable: true,
    },
    
    {
      name: "Amount",
      selector: (row) =>
        row.amount && row.currency
          ? `${row.amount} ${row.currency.toUpperCase()}`
          : "-",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type || "-",
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
        row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB")
          : "-",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span
  className={`badge ${
    (row.status || "").toLowerCase() === "succeeded"
      ? "badge badge-success"
      : (row.status || "").toLowerCase() === "paid"
      ? "badge badge-success"
      : (row.status || "").toLowerCase() === "pending"
      ? "badge badge-warning"
      : (row.status || "").toLowerCase() === "failed"
      ? "badge badge-danger"
      : "badge badge-info"
  }`}
>
  {(row.status || "").toLowerCase() === "succeeded"
    ? "Completed"
    : (row.status || "").toLowerCase() === "paid"
    ? "Completed"
    : (row.status || "").toLowerCase() === "pending"
    ? "Pending"
    : (row.status || "").toLowerCase() === "failed"
    ? "Failed"
    : "-"}
</span>

      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        row.url ? (
          <button
            className="btn bg-gradient-warning btn-sm"
            onClick={() => handleDownload(row.url)}
          >
            View
          </button>
        ) : (
          "-"
        ),
    },
  ];

  const handleDownload = (url) => {
    if (url) {
      const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(url);
      if (isValidUrl) window.open(url, "_blank");
      else alert("Invalid receipt URL.");
    } else alert("Receipt URL is not available.");
  };

  return (
    <Container fluid className="px-0">
      {(paymentData || []).length === 0 ? (
        <div className="d-flex justify-content-center align-items-center ">
          <p className="text-center mb-0">
            You have no invoices. When you make a purchase, it will appear here.
          </p>
        </div>
      ) : (
        <Row className="mb-1">
          <Col lg={12}>
            {/* Search Bar */}
            <div className="search-button-container px-0 py-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Invoices"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>

            {/* DataTable */}
            <div className="table-container">
              <DataTable
                className="invoice-data-table"
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                noHeader={false}
                persistTableHead={true}
                noDataComponent={
                  <div className="text-center py-3">
                    No Invoice Data Found
                  </div>
                }
                customStyles={{
                  headCells: {
                    style: {
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#EBE7FC",
                      fontWeight: "bold",
                      fontSize: "14px",
                      zIndex: 10,
                      fontFamily: "Proxima Nova",
                    },
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Allinvoice;
