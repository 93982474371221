import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CustomizePlan = () => {
  return (
    <>
    <div>
      <Container fluid className="py-5 d-flex justify-content-center">
        {/* <div className="bgsection6"> */}
        <div className="card shadow-lg customize-plan">
          <h2 className="fw-bold mb-4 customize-plan-title">
          Are you looking for a customized plan?
          </h2>
          <div className="d-flex justify-content-start gap-3 mb-4">
            <Link to="/contact">
              <Button className="talk-to-your-team-btn">
                Talk to our team
              </Button>
            </Link>
            
          </div>

        </div>
        {/* </div> */}
      </Container>
    </div>
    </>
  )
}

export default CustomizePlan