import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import Loading from "../../../assets/img/dashbord/loading.gif";

const ShowHeaders = () => {
  const { _id } = useParams();
  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
    leadFields: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const state = useLocation();
  const { csvData } = state?.state || {};

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${baseURL}campaign/getCampaignByCampaignId/${_id}`, { headers })
      .then((response) => {
        const { campaignName, status, recordCount, leadFields = [] } = response.data;
        setIdData({ campaignName, status, recordCount, leadFields });
      })
      .catch((err) => {
        if (err.response?.status === 400) return navigate("/login");
        if (err.response?.status === 401) return navigate("/");
        toast(err?.response?.data?.message || "Error fetching data", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNext = () => {
    navigate(`/account/importLeads/${_id}`, {
      state: { rowCount: IdData.recordCount, campaignId: _id, csvData },
    });
  };

  const handlePrev = () => {
    navigate(`/account/uploadcsv/${_id}`);
  };

  return (
    isLoading ? (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <img src={Loading} alt="Loading" width={25} />
      </div>
    ) : (
      <Container fluid>
        <Container className="h-100 w-75 p-4 border border-2 rounded bg-white d-flex flex-column">
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="fw-bold">2/3</div>
                <div className="fs-5">Set up imported custom headers</div>
                <button className="border-0 bg-transparent" onClick={handlePrev}>
                  <RxCross1 size={24} />
                </button>
              </div>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col>
              <div className="mb-3 fw-bold">Headers Name</div>
              <div
                className="headersSection border border-2 rounded p-3"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                {IdData.leadFields.length > 0 ? (
                  <div
                    className="py-1 gap-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "10px",
                    }}
                  >
                    {IdData.leadFields.map((field) => (
                      <div
                        className="border border-bottom p-2 rounded-2 d-flex align-items-center"
                        key={field._id}
                      >
                        <Form>
                          <Form.Check
                            type="checkbox"
                            id={`default-${field._id}`}
                            className="custom-checkbox me-2"
                            checked
                            disabled
                          />
                        </Form>
                        {field.label}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-center w-100">No headers available</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="headersSection border border-2 rounded p-2 mt-3">
                <div className="p-2 rounded-2 d-flex align-items-center">
                  <Form>
                    <Form.Check type="checkbox" className="custom-checkbox me-2 ms-2" />
                  </Form>
                  Verify your leads
                </div>
                <div className="p-2 rounded-2 d-flex align-items-center">
                  <Form>
                    <Form.Check type="checkbox" className="custom-checkbox me-2 ms-2" />
                  </Form>
                  Enrich your leads
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button className="previous mx-2" onClick={handlePrev}>
                  Previous
                </button>
                <button className="btn bg-gradient-warning" onClick={handleNext}>
                  {`Import ${IdData.recordCount} Leads`}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  );
};

export default ShowHeaders;

