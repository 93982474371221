import React, { useState, useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import logo from "../assets/img/svg/logo/bluelogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { isLogin, logOut } from "../utils/auth"; // Adjust the import path as necessary

function Navigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in when the component mounts
    const checkLoginStatus = async () => {
      const loggedIn = await isLogin(navigate);
      setIsLoggedIn(loggedIn);
    };

    checkLoginStatus();

    // Handle scroll event
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle logout
  const handleLogout = () => {
    logOut();
    setIsLoggedIn(false);
  };

  return (
    <header
      className={`smartqc-header d-flex justify-content-center  ${
        isScrolled ? "scrolled sticky-top" : ""
      }`}
    >
      <Navbar
        expand="lg"
        className={`shadow px-4 py-2 newnavbar ${
          isScrolled ? "full-width" : "rounded-5"
        }`}
        bg="white"
      >
        <Container fluid>
          <Navbar.Brand to={"/"} as={Link} className="p-1 rounded-2 img-fluid">
            <img src={logo} alt="Logo" width="100%" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="align-items-center gap-3">
              {/* <Nav.Link as={Link} to="/billing/plans" className="text-primary">
                Plan
              </Nav.Link> */}

              <Nav.Link as={Link} to="/contact" className="text-primary">
                Contact Us
              </Nav.Link>

              {/* Conditionally render buttons based on login status */}
              {!isLoggedIn ? (
                <>
                  <Link to="/login">
                    <Button
                      variant="me-2 sign"
                      className="me-2 btn-header-signin"
                    >
                      Sign In
                    </Button>
                  </Link>
                  <Link to="/register">
                    <Button className="btn-header-start-free border-0">
                      Start for Free
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/account/dashboard">
                    <Button
                      variant="me-2"
                      className="me-2 text-primary border-white border-2"
                    >
                      My Account
                    </Button>
                  </Link>
                  <Button
                    variant="me-2"
                    onClick={handleLogout}
                    className="text-primary rounded-3"
                  >
                    Logout
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Navigation;
