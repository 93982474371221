import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { baseURL } from "../../utils/constant";
import { getCookie } from "../../utils/auth";

// Load Stripe.js with your publishable key
const stripePromise = loadStripe("your-publishable-key");

const AddCredits = () => {
  const pricePerCredit = 1; // Fixed price per credit
  const [credits, setCredits] = useState(100); // Default credits
  const [totalPrice, setTotalPrice] = useState(0); // Total cost

  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };

  // Calculate total price whenever credits change
  useEffect(() => {
    setTotalPrice(credits * pricePerCredit);
  }, [credits]);

  // Handle slider change
  const handleCreditsChange = (value) => {
    if (value === "") {
      setCredits(""); // Allow clearing input
    } else {
      setCredits(Number(value));
    }
  };

  // Stripe Checkout handler
  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise;

      const payload = {
        unit_amount: totalPrice * 100, // Convert to cents
        currency: "usd",
        product_data: {
          name: "Top-up",
        },
        credits,
      };

      const config = {
        headers,
      };

      const response = await axios.post(
        `${baseURL}payment/stripe-checkout-session`,
        payload,
        config
      );

      const session = response.data;

      if (session.url) {
        window.location = session.url; // Redirect to Stripe Checkout
      } else {
        alert("Failed to redirect to checkout.");
      }
    } catch (error) {
      alert("An error occurred while processing your request.");
    }
  };

  return (
    <div className="add-credit py-3 px-5">
      <Container fluid>
        <Row>
          <Col className="card p-5 bg-white vh-100">
            <Row>
              <Col lg={8}>
                <div className="py-2"></div>
                <Row>
                  <Col
                    lg={7}
                    md={6}
                    sm={12}
                    className="bg-grey p-3 shadow border border-topup "
                  >
                    <h4 className="mt-4">Get Estimate</h4>
                    <Form>
                      {/* Slider for selecting credits */}
                      <Form.Group className="mb-4">
                        <Form.Label></Form.Label>
                        <Row className="d-flex justify-content-center py-2 align-items-center">
                          <Col lg={4} className="p-0">
                            <Form.Control
                              type="number"
                              value={credits}
                              onChange={({ target: { value } }) =>
                                handleCreditsChange(value)
                              }
                              onBlur={() =>
                                setCredits((prev) => (prev < 100 ? 100 : prev))
                              }
                              min={100}
                              max={100000}
                            />
                          </Col>
                          <Col lg={4} className="p-0">
                            <span className="p-2">Credits</span>
                          </Col>
                          <Col lg={9}>
                            <RangeSlider
                              min={100}
                              max={100000}
                              step={10}
                              value={credits}
                              onChange={(e) =>
                                handleCreditsChange(e.target.value)
                              }
                              className="sq-db-range-slider m-0"
                              style={{
                                background: `linear-gradient(to right, #3966EF ${
                                  ((credits - 1) / (100000 - 1)) * 100
                                }%, #e0e0e0 ${
                                  ((credits - 1) / (100000 - 1)) * 100
                                }%)`,
                              }}
                            />
                          </Col>
                          <Col lg={12}>
                            <p className="mt-3">
                              Slide to select the number of credits you want to
                              purchase. Click "Top-Up Now" to proceed with
                              payment.
                            </p>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                  </Col>

                  {/* Right Section: Price Summary */}
                  <Col
                    lg={5}
                    md={6}
                    sm={12}
                    className="bg-orange p-4 text-white rounded-0"
                  >
                    <span className="py-3">
                      <strong>Selected Credits:</strong> {credits}
                    </span>
                    <hr />
                    <h4 className="text-white mb-0 text-sm">Total Price</h4>
                    <h2 className="text-white opacity-8 mb-0 ">
                      ${totalPrice.toFixed(2)}
                    </h2>
                    <p>Overall cost for selected credits</p>
                    <div className="mb-3">
                      <strong>Price Per Credit:</strong> ${pricePerCredit}
                    </div>
                    <Button
                      variant="dark"
                      size="lg"
                      className="fw-bold text-sm bg-white text-primary"
                      onClick={handleCheckout}
                    >
                      Buy Now
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddCredits;
