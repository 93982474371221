import React from "react";
import { Button } from "react-bootstrap";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const RecentCampaigns = ({ latestCampaigns }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="card-header p-3 pb-0">
        <h6 className="mb-0">Recent Campaigns</h6>
        <hr className="horizontal dark mb-0" />
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table align-items-center mb-0">
            <tbody>
              {latestCampaigns.length > 0 ? (
                latestCampaigns.map((campaign) => (
                  <tr key={campaign._id}>
                    <td>
                      <h4 className="text-xs mb-0 text-capitalize">{campaign.campaignName}</h4>
                      <small className="text-secondary">
                        Created at -{" "}
                        {new Date(campaign.createdAt).toLocaleDateString()}
                      </small>
                    </td>
                    <td className="align-middle text-center">
                      <span
                        className={`badge badge-sm badge-${
                          campaign.status === "New"
                            ? "info"
                            : campaign.status === "Pending"
                            ? "warning"
                            : campaign.status === "Complete"
                            ? "success"
                            : ""
                        }`}
                      >
                        {campaign.status}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    No recent campaigns available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            className="border-0 bg-gradient-warning p-2"
            onClick={() => navigate("/account/campaigns")}
          >
            <HiOutlineEye />  View All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecentCampaigns;
