import React, { useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCross1 } from "react-icons/rx";
import csvfileicon from "../../../assets/img/svg/csvfileicon.svg";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import { getCookie } from "../../../utils/auth";
import Swal from "sweetalert2";

const Uploadcsv = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [rowCount, setRowCount] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isCSV, setIsCSV] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const bearerToken = getCookie("bearerToken");
  const headers = bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {};
  const { _id } = useParams();

  const { state } = useLocation();
  const leadFieldLabels = state?.leadFieldLabels || [];

  const resetFileState = () => {
    setSelectedFile(null);
    setFileName("");
    setRowCount(null);
    setIsCSV(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (file) => {
    if (!file) return;
    resetFileState();
    setFileName(file.name);
    setSelectedFile(file);

    if (file.type === "text/csv" || file.name.endsWith(".csv")) {
      setIsCSV(true);

      Papa.parse(file, {
        complete: (result) => {
          let csvData = result.data;
          if (csvData.length === 0) {
            toast.error("CSV file is empty.");
            resetFileState();
            return;
          }

          const cleanHeader = (str) => {
            return str
              ?.toString()
              .trim()
              .replace(/[^a-zA-Z0-9]/g, "")
              .toLowerCase();
          };

          let rawHeaders = Object.keys(csvData[0] || {});
          let cleanedCsvHeaders = rawHeaders.map(cleanHeader);

          let cleanedLeadFields = leadFieldLabels.map(cleanHeader);

          let hasEmail = cleanedCsvHeaders.includes("email");

          let uniqueHeaders = cleanedLeadFields.filter((field) => {
            if (hasEmail && field === "emailaddress") return false;
            return !cleanedCsvHeaders.includes(field);
          });

          let updatedCsvData = csvData.map((row) => {
            let updatedRow = {};

            Object.keys(row).forEach((key) => {
              let cleanedKey = cleanHeader(key);
              updatedRow[cleanedKey] = row[key];
            });

            uniqueHeaders.forEach((field) => {
              if (!updatedRow.hasOwnProperty(field)) {
                updatedRow[field] = "";
              }
            });

            return updatedRow;
          });

          let finalHeaders = Array.from(new Set([...cleanedCsvHeaders, ...uniqueHeaders]));

          const updatedCsv = Papa.unparse(updatedCsvData, { header: true, columns: finalHeaders });

          const updatedFile = new File([updatedCsv], file.name, { type: "text/csv" });

          setSelectedFile(updatedFile);
          setRowCount(csvData.length);
        },
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        error: (error) => {
        
          setRowCount(null);
        },
      });
    } else {
      toast.error("Please upload a valid CSV file.");
      resetFileState();
    }
  };

  const handleRemoveFile = () => {
    resetFileState();
  };

  const handleFileUpload = async () => {
    if (!bearerToken) {
      alert("Authentication failed! Please log in again.");
      return;
    }

    if (selectedFile && isCSV) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("campaignId", _id);

      setIsUploading(true);
      try {
        const response = await axios.post(
          `${baseURL}campaign/importAnyFile`,
          formData,
          { headers }
        );

        const campaignId = response.data.campaignId || _id;
        Papa.parse(selectedFile, {
          complete: (result) => {
            navigate(`/account/showheaders/${campaignId}`, {
              state: { rowCount, csvData: result.data },
            });
          },
          header: true,
        });
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error.message;
        Swal.fire({
          title: errorMessage,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f76a28",
          cancelButtonColor: "#000",
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(errorMessage === "Leads already uploaded" ? `/account/uploadcsv/${_id}` : "/account/addcredits");
          }
        });
      } finally {
        setIsUploading(false);
      }
    } else {
      toast.warning("Please select a valid CSV file first!");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    handleFileChange(file);
  };

  return (
    <>
      <div>
        <div className="mb-3 my-3 ms-4">
          <Link
            to="/account/campaigns"
            className="text-decoration-none link-style-none text-black fs-6 "
          >
            <span className="text-muted">Back to Campaigns</span>
          </Link>
        </div>

        <div className="d-flex justify-content-center align-items-center py-5" style={{ backgroundColor: "#f1f1f1" }}>
          <div
            className={`card shadow-sm p-4`}
            style={{ width: "450px" }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="mb-0 fw-bold"><span className="me-2">1/3</span> Upload a CSV</h6>
              <button className="border-0 bg-transparent" onClick={() => navigate(`/account/createcampaign/`)}>
                <RxCross1 />
              </button>
            </div>

            <div className="text-center py-3 border rounded bg-light mb-4">
              <img src={csvfileicon} alt="CSV File Icon" className="mb-3" />
              <p className="mb-0">
                <label htmlFor="fileUpload" className="text-orange text-decoration-underline" style={{ cursor: "pointer" }}>
                  Click to upload
                </label>
                <input
                  ref={fileInputRef}
                  id="fileUpload"
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e.target.files[0])}
                />
                &nbsp;or drag and drop anywhere.
              </p>
              <small className="text-muted">Only CSV files are accepted.</small>
            </div>

            {fileName && (
              <div className="p-3 border rounded mb-4 bg-white d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-1 fw-bold">{fileName}</p>
                  <small className="text-muted">{isCSV ? (rowCount !== null ? `${rowCount} leads found` : "Loading...") : "Please upload a CSV file"}</small>
                </div>
                <button className="border-0 bg-transparent text-danger" onClick={handleRemoveFile}>
                  <RxCross1 />
                </button>
              </div>
            )}

            <div className="text-end">
              <button className="btn btn-primary text-white px-4" onClick={handleFileUpload} disabled={!isCSV || isUploading}>
                {isUploading ? "Uploading..." : "Continue"}
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Uploadcsv;

