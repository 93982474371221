// import { Button } from 'bootstrap';
// import { Modal } from 'bootstrap';
import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";

import axios from "axios";
import { useNavigate } from "react-router-dom"; // Corrected hook name
import { toast } from "react-toastify";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import Uploadcsv from "./Uploadcsv";
import Loading from "../../../assets/img/dashbord/loading.gif";

function NewCampaign() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [leadsData, setLeadsData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const navigate = useNavigate();

  const [leadFields, setLeadFields] = useState([
    { name: "first_name", type: "String", label: "First Name" },
    { name: "last_name", type: "String", label: "Last Name" },
    { name: "company_name", type: "String", label: "Company Name" },
    { name: "email", type: "String", label: "Email Address" },
  ]);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${baseURL}leadField/getLeadFields`, { headers: { ...headers } })
      .then((response) => {
        const responseData = response.data;

        const leadsArray = Array.isArray(responseData)
          ? responseData
          : responseData.data;
        setLeadsData(leadsArray);
     
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // handleLeadFieldClick

  const handleLeadFieldClick = (fieldName) => {
    const isDisabledField = [
      "first_name",
      "last_name",
      "company_name",
      "email",
    ].includes(fieldName);

    if (isDisabledField) {
      return;
    }

    const isSelected = leadFields.find((field) => field.name === fieldName);

    if (isSelected) {
      const updatedLeadFields = leadFields.filter(
        (field) => field.name !== fieldName
      );
      setLeadFields(updatedLeadFields);
    } else {
      const leadField = leadsData.find((field) => field.name === fieldName);
      if (leadField) {
        setLeadFields((prevSelected) => [...prevSelected, leadField]);
      }
    }
  };

  // handleLeadFieldClick

  // const handleImportNewLead = () => {
  //   if (!campaignName || leadFields.length === 0) {
  //     toast("Please enter a campaign name and select at least one field.", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     return;
  //   }

  //   setLoading(true);
  //   axios
  //     .post(
  //       `${baseURL}campaign/createCampaign`,
  //       {
  //         leadFields: leadFields,
  //         campaignName: campaignName,
  //       },
  //       { headers: { ...headers } }
  //     )
  //     .then((response) => {
  //       const campaignId = response.data.data._id;
  //       toast("Campaign created successfully!", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       setCampaignId(campaignId);

  //       // ✅ Pass data using state
  //       navigate(`/account/uploadcsv/${campaignId}`, {
  //         state: { leadFields, campaignId }
  //       });
  //     })
  //     .catch((err) => {
  //       toast(err?.response?.data?.message || "Error creating campaign", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const handleImportNewLead = () => {
    if (!campaignName || leadFields.length === 0) {
      toast("Please enter a campaign name and select at least one field.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setLoading(true);
    axios
      .post(
        `${baseURL}campaign/createCampaign`,
        {
          leadFields: leadFields,
          campaignName: campaignName,
        },
        { headers: { ...headers } }
      )
      .then((response) => {
        const campaignId = response.data.data._id;
        toast("Campaign created successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCampaignId(campaignId);

        // ✅ Send only lead field labels
        const leadFieldLabels = leadFields.map((field) => field.label);

        navigate(`/account/uploadcsv/${campaignId}`, {
          state: { leadFieldLabels, campaignId },
        });
      })
      .catch((err) => {
        toast(err?.response?.data?.message || "Error creating campaign", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // i enter  a name in form control and selecta filed then click on import new lead button then create a post request and create a campaign id

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <img src={Loading} alt="Loading" width={25} />
        </div>
      ) : (
        <Container>
          <Row className="w-100">
            <Col
              xs={12}
              md={8}
              lg={12}
              className="my-5 d-flex flex-column align-items-center justify-content-center text-center"
            >
              <div className="d-flex flex-column align-items-center p-3 shadow bg-body-tertiary rounded">
                <div className="icon-container mb-3">
                  <FaUsers className="user-icons" size={70} />
                </div>
                <p className="text-muted">Import your own leads.</p>
                <div className="py-2 w-25">
                  <Form.Control
                    placeholder="Campaign Name"
                    className="mb-4 rounded-3 text-capitalize"
                    required
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                  />
                </div>
  
                {/* lead fields */}
                <div
                  className="container border border-1 overflow-auto"
                  style={{ overflowY: "scroll", maxHeight: "200px" }}
                >
                  <p className="text-center">Headers :</p>
                  <div className="d-flex flex-wrap justify-content-center">
                    {leadsData.map((leadField, index) => {
                      const isSelected = leadFields.find(
                        (selected) => selected.name === leadField.name
                      );
  
                      return (
                        <div key={index} className="px-2">
                          {isSelected ? (
                            <span
                              className="m-1 badge badge-success badge-sm text-white selecteleadfiledsbtn"
                              onClick={() => handleLeadFieldClick(leadField.name)}
                            >
                              {leadField.label}
                            </span>
                          ) : (
                            <span
                              as="button"
                              className="p-2 m-1 button-outline border-0 badge badge-success badge-sm"
                              onClick={() => handleLeadFieldClick(leadField.name)}
                            >
                              {leadField.label}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
  
                {/* lead fields */}
  
                <button
                  className="btn bg-gradient-warning px-3 mt-3 w-50 text-capitalize"
                  onClick={handleImportNewLead}
                >
                  <FiPlus size={25} />
                  Import new leads
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
  
}

export default NewCampaign;
