import React, { useState } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/images/logo/newbluelogo.png";
import { GoPlus } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { CiBullhorn, CiUser } from "react-icons/ci";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";



// import ImHeadphones from "react-icons/lib/im/headphones";

function NewSidebar2() {
  const userData = useSelector((state) => state.dashboard.dashboardData.user);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  return (
    <>
      <Col lg={sidebarOpen ? 2 : 1} className={`py-4 px-4 bg-white vh-100 sb-shadow-right sidebar ${sidebarOpen ? "expanded" : "collapsed"}`} style={{width: "auto"}}>

        {!sidebarOpen ? (
          <div className="d-grid gap-2 mb-4">
            <Button
              variant="btn bg-gradient-warning py-2 p-0"
              onClick={() => navigate("/account/createcampaign")}
            >
              <span className="me-1">
                <GoPlus size={"30px"} />
              </span>
            </Button>
          </div>
        ) : (
          <div className="d-grid gap-2 mb-4" style={{ width: "160px" }}>
            <Button
              className="btnnewcampaign"
              variant="btn bg-gradient-warning py-2 p-0"
              onClick={() => navigate("/account/createcampaign")}
            >
              <span className="me-1">
                <GoPlus size={"30px"} />
              </span>
              New Campaign
            </Button>
          </div>
        )}
        <Nav defaultActiveKey="/home" className="flex-column vh-100 d-flex">
          <Link className="text-decoration-none fs-6 text-dark mb-3 p-2 dashboard" to="/account/dashboard">
            <span className="me-2">
              <RxDashboard />
            </span>
            {sidebarOpen && "Dashboard"}
          </Link>
          <Link className="text-decoration-none fs-6 text-dark mb-3 p-2 dashboard" to="/account/campaigns">
            <span className="me-2">
              <CiBullhorn />
            </span>
            {sidebarOpen && "Campaigns"}
          </Link>
          <Link className="text-decoration-none fs-6 text-dark mb-3 p-2 dashboard" to="/account/billing">
            <span className="me-2">
              <LiaFileInvoiceSolid />
            </span>
            {sidebarOpen && "Billing"}
          </Link>

          <Link className="text-decoration-none fs-6 text-dark mb-3 p-2 dashboard" to="/account/profile">
            <span className="me-2">
              <FaRegUser />
            </span>
            {sidebarOpen && "Profile"}
          </Link>
          <Link className="text-decoration-none fs-6 text-dark mb-3 p-2 dashboard" to="/account/settings">
            <span className="me-2">
              <IoSettingsOutline />
            </span>
            {sidebarOpen && "Settings"}
          </Link>
          <div className=" sidebar-card">
            <Row>
              {sidebarOpen && (
                <Col lg={12} className="sq-sidbar-bg-grey rounded-4  p-2">
                  <Row className="d-lg-flex">
                    <Col className="text-start ">
                      <div className="pb-2">
                        <div className="d-flex justify-content-start align-items-center">
                          {new Date(userData.planExpiresOn) < new Date() ? null : (
                            <div className="ActivePlan">Active Plan</div>
                          )}
                          {new Date(userData.planExpiresOn) < new Date() ? (
                            <div className="text-danger fs-6 text-center">Your plan has expired</div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <h5 className="fw-bold ms-2" title={userData.planName}>
                                {userData.planName.length > 15
                                  ? `${userData.planName.substring(0, 15)}...`
                                  : userData.planName}
                              </h5>
                            </div>

                          )}
                        </div>
                        <div className="d-flex">
                          {new Date(userData.planExpiresOn) >= new Date() && (
                            <>
                              <div>Expires At</div>
                              <span className="fs-6 text-dark fw-bold ms-2">
                                {getFormattedDate(userData.planExpiresOn)}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <button
                          className=" btn bg-upgradePlan w-100"
                          onClick={() => navigate("/account/billing/plans")}
                        >
                          Upgrade
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </Nav>
      </Col>
      <div className="d-block justify-content-between sidebar-collapse-icon align-items-center mb-4">

        <Button variant="outline-primary" onClick={toggleSidebar} className="toggle-btn">
          {sidebarOpen ? "⮜" : "⮞"}
        </Button>
      </div>
    </>
  );
}

export default NewSidebar2;
