import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FaUser, FaEnvelope, FaMapMarkerAlt, FaCity, FaHashtag } from "react-icons/fa";
import axios from "axios";
import { getCookie } from "../../utils/auth";
import { toast } from "react-toastify";
import { baseURL } from "../../utils/constant";
import Flag from "react-world-flags";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/dashboardSlice";
import { use } from "react";
import { set } from "date-fns";

const MyAccount = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCountriesAndStates();
  }, []);

  const { user } = useSelector((state) => state.dashboard.dashboardData);
  console.log(user);
  

 useEffect(() => {
  if (user && Object.keys(user).length > 0) {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setEmail(user.email || "");
    setAddress1(user.address_line_one || "");
    setAddress2(user.address_line_two || "");
    setCity(user.city || "");
    setState(user.state || "");
    setPincode(user.pincode || "");

    // Set Country & Fetch States
    if (user.country) {
      console.log("User Data:", user);
      const selectedCountry = countries.find((c) => c.value === user.country);
      setCountry(selectedCountry);
      fetchStates(user.country);
    }
  }
}, [user, countries]);


  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      firstName,
      lastName,
      email,
    };
    try {
      const response = await axios.post(`${baseURL}user/editUserByUserId`, payload, { headers });
      if (response.status === 200) {
        toast.success("Personal information updated successfully!", { position: "bottom-right" });
        dispatch(fetchDashboardData( { source: ["user"], reload: false}));
      }
    } catch (err) {
      toast.error("Failed to update personal information. Please try again.", { position: "bottom-right" });
    }
  };

  const fetchCountriesAndStates = async () => {
    try {
      const response = await axios.get("https://countriesnow.space/api/v0.1/countries/states");
      setCountries(response.data.data.map(country => ({
        label: country.name,
        value: country.name,
        iso2: country.iso2,
        states: country.states,
      })));
    } catch (err) {
      toast.error("Failed to fetch countries and states", { position: "bottom-right" });
    }
  };

  const fetchStates = (selectedCountry) => {
    const countryData = countries.find(c => c.value === selectedCountry);
    if (countryData) {
      setStates(countryData.states.map(state => ({              
        label: state.name,
        value: state.state_code,
      })));
    }
  };

const handleCountryChange = (selectedCountry) => {
  if (selectedCountry) {
    setCountry(selectedCountry);
    setErrors((prevErrors) => ({ ...prevErrors, country: "" }));
    fetchStates(selectedCountry.value);
  } else {
    setCountry(null); // Ensure state resets when cleared
    setStates([]);
  }
};

  const handleStateChange = (selectedState) => {
    if (selectedState) {
      setState(selectedState.value);
      setErrors((prevErrors) => ({ ...prevErrors, state: "" }));
    }
    else {
      setState(null); // Ensure state resets when cleared
    }
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateAddress();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      address_line_one: address1,
      address_line_two: address2,
      city,
      state,
      country: country.value,
      postal: pincode,
    };

    try {
      const response = await axios.post(`${baseURL}payment/saveBillingAddress`, payload, { headers });
      if (response.status === 200) {
        toast.success("Address saved successfully!", { position: "bottom-right" });
        dispatch(fetchDashboardData({ source: ["user"], reload: false }));
        setErrors({});
      }
    } catch (error) {
      toast.error("Failed to save address. Please try again.", { position: "bottom-right" });
    }
  };
  const [addressErrors, setAddressErrors] = useState({});

  const validateAddress = () => {
    const errors = {};
    if (address1.length < 5 || address1.length > 250) {
      errors.address1 = "Address Line 1 must be between 5 and 250 characters.";
    }
    if (!country) {
      errors.country = "Country is required.";
    }
    if (!state) {
      errors.state = "State is required.";
    }
    if (city.length < 3 || city.length > 100) {
      errors.city = "City must be between 3 and 100 characters.";
    }
    const pincodeRegex = /^[a-zA-Z0-9]{6}$/;
    if (!pincode.match(pincodeRegex)) {
      errors.pincode = "Pincode must be a 6-character alphanumeric value.";
    }
    setAddressErrors(errors);
    return errors;
  };

  
  

  return (
    <Container fluid className="p-4 bg-light">
      <Row className="g-4 d-flex flex-row">
        <h3 className="fw-bold">My Account</h3>
        <Col md={6} >
          <div className="p-4 bg-white shadow rounded-4 h-100">
            <h5 className="mb-4 text-dark">Personal Information</h5>
            <Form onSubmit={handlePersonalInfoSubmit}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      First Name <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaUser />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        className={`px-2 ${errors.firstName ? "is-invalid" : ""} text-capitalize `}
                        value={firstName}
                        onChange={(e) => {
                          const formattedName =
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase();
                          setFirstName(formattedName);
                        }}
                      />
                      {errors.firstName && (
                        <div className="invalid-feedback px-2">{errors.firstName}</div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Last Name <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaUser />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Last Name"
                        className={`px-2 ${errors.lastName ? "is-invalid" : ""} text-capitalize`}
                        value={lastName}
                        onChange={(e) => {
                          const formattedName =
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase();
                          setLastName(formattedName);
                        }}
                      />
                    </InputGroup>
                    {errors.lastName && (
                      <div className="invalid-feedback px-2">{errors.lastName}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">Email Address</Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaEnvelope />
                      </InputGroup.Text>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                        className="px-2"
                        disabled
                        value={email}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="d-flex justify-content-center">
                  <Button variant="primary" type="submit" className="rounded-pill px-4 py-2  bg-gradient-warning border-0">
                    Update Information
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>

        <Col md={6}>
          <div className="p-4 bg-white shadow rounded-4">
            <h5 className="mb-4 text-dark">Billing Address</h5>
            <Form onSubmit={handleAddressSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Address Line 1 <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaMapMarkerAlt />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address Line 1"
                        value={address1}
                        onChange={e => setAddress1(e.target.value)}
                        className={`px-2 ${errors.address1 ? "is-invalid" : ""}`}
                      />
                      {errors.address1 && (
                        <div className="invalid-feedback px-2">{errors.address1}</div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Address Line 2
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaMapMarkerAlt />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address Line 2"
                        value={address2}
                        onChange={e => setAddress2(e.target.value)}
                        className="px-2"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>

              <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Country <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      options={countries}
                      value={country}
                      onChange={handleCountryChange}
                      getOptionLabel={(e) => (
                        <div className="d-flex align-items-center">
                          <Flag code={e.iso2} style={{ width: 20, height: 20 }} />
                          <span className="ms-2">{e.label}</span>
                        </div>
                      )}
                      className={`px-2 border-0  ${errors.country ? "is-invalid" : ""}`}
                      placeholder="Select Country"
                      isSearchable
                      isClearable
                      noOptionsMessage={() => "No countries found"}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          fontFamily: "Proxima Nova",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: "#333",
                          backgroundColor: "#fff",
                          border: state.isFocused
                            ? "1px solid #3966EF"
                            : "1px solid #d2d6da",
                          borderRadius: 10,
                          boxShadow: state.isFocused
                            ? "0 0 0 2px rgba(57,102,239,.25)"
                            : "none",
                          cursor: "pointer",
                          transition: "all .2s ease-in-out",
                          zIndex:10,
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          fontFamily: "Proxima Nova",
                          fontSize: "10px",
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: "#333",
                          backgroundColor: "#fff",
                          border: state.isFocused
                            ? "1px solid #3966EF"
                            : "1px solid #d2d6da",
                          borderRadius: 0,
                          boxShadow: state.isFocused
                            ? "0 0 0 2px rgba(57,102,239,.25)"
                            : "none",
                          padding: ".5rem 1rem",
                          cursor: "pointer",
                          transition: "all .2s ease-in-out",
                          
                        }),

                      }}
                      formatOptionLabel={(option) => (
                        <div className="d-flex align-items-center">
                          <Flag code={option.iso2} style={{ width: 20, height: 20 }} />
                          <span className="ms-2">{option.label}</span>
                        </div>
                      )}
                      getOptionValue={(e) => e.value}
                      
                    />
                    {errors.country && <div className="invalid-feedback px-2">{errors.country}</div>}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      State <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      options={states}
                      value={states.find(s => s.value === state)}
                      onChange={handleStateChange}
                      placeholder="Select State"
                      className={` px-2 border-0  ${errors.state ? "is-invalid" : ""}`}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      isSearchable
                      isClearable
                      noOptionsMessage={() => "No states found"}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          fontFamily: "Proxima Nova",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: "#333",
                          backgroundColor: "#fff",
                          border: state.isFocused
                            ? "1px solid #3966EF"
                            : "1px solid #d2d6da",
                          borderRadius: 10,
                          boxShadow: state.isFocused
                            ? "0 0 0 2px rgba(57,102,239,.25)"
                            : "none",
                          cursor: "pointer",
                          transition: "all .2s ease-in-out",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          fontFamily: "Proxima Nova",
                          fontSize: "10px",
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: "#333",
                          backgroundColor: "#fff",
                          border: state.isFocused
                            ? "1px solid #3966EF"
                            : "1px solid #d2d6da",
                          borderRadius: 0,
                          boxShadow: state.isFocused
                            ? "0 0 0 2px rgba(57,102,239,.25)"
                            : "none",
                          padding: ".5rem 1rem",
                          cursor: "pointer",
                          transition: "all .2s ease-in-out",
                        }),
                      }}
                    />
                    {errors.state && <div className="invalid-feedback px-2">{errors.state}</div>}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      City <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaCity />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter City"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        className={`px-2 ${errors.city ? "is-invalid" : ""} text-capitalize`}
                      />
                      {errors.city && (
                        <div className="invalid-feedback px-2">{errors.city}</div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Pincode <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaHashtag />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Pincode"
                        value={pincode}
                        onChange={e => setPincode(e.target.value)}
                        className={`px-2 ${errors.pincode ? "is-invalid" : ""}`}
                      />
                      {errors.pincode && (
                        <div className="invalid-feedback px-2">{errors.pincode}</div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className="fw-semibold text-secondary">
                      Pincode <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="">
                      <InputGroup.Text className="bg-white text-secondary">
                        <FaHashtag />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter Pincode"
                        value={pincode}
                        onChange={e => setPincode(e.target.value)}
                        className={` ${errors.pincode ? "is-invalid" : ""}`}
                      />
                      {errors.pincode && (
                        <div className="invalid-feedback">{errors.pincode}</div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col> */}
              </Row>

              <Row>
                <Col lg={12} className="d-flex justify-content-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill px-4 py-2  bg-gradient-warning border-0"
                  >
                    Save Address
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MyAccount;
