import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';


const PlanAlert = () => {

  const navigate = useNavigate();

  const handleUpgradePlan = () => {
    navigate('/account/billing/plans');
  };


  return (
    <Container fluid className="mt-3">
      <Alert variant="warning">
        <Row>
          <Col>
            <p className="mb-0">
              Your current plan is not active. Please upgrade your plan to continue using SmartQC.
              <Button variant="link" onClick={handleUpgradePlan}>
                Upgrade Plan
              </Button>
            </p>
          </Col>
        </Row>
      </Alert>
    </Container>
  )
}

export default PlanAlert

