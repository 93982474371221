import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../redux/dashboardSlice"; //Import the async thunk
import { useLocation } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { clearAuthentication, getCookie } from "../utils/auth";
import {
  PiCoinsLight,
  PiCoinThin,
  PiCoinVertical,
  PiUserCircleLight,
} from "react-icons/pi";
import axios from "axios";
import { baseURL } from "../utils/constant";
import { MdCheckCircle, MdOutlinePowerSettingsNew } from "react-icons/md";
import { MdSync } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { CiBullhorn } from "react-icons/ci";
import logo from "../assets/img/images/logo/newbluelogo.png";

function formatNumber(value) {
  if (value < 1000) {
    return value.toString(); // Keep it as is for numbers below 1000
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // Format to 'K'
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Format to 'M'
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B"; // Format to 'B'
  }
}

const SubNav = () => {
  const campaignData = useSelector((state) => state.dashboard.campaignData);
  //find count of campaign data status active
  const PendingcampaignData = campaignData.filter(
    (data) => data.status === "Pending"
  ).length;
  const NewcampaignData = campaignData.filter(
    (data) => data.status === "New"
  ).length;
  const CompletecampaignData = campaignData.filter(
    (data) => data.status === "Complete"
  ).length;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Access Redux state
  const userData = useSelector((state) => state.dashboard.dashboardData.user);

  const campaignCount = useSelector(
    (state) => state.dashboard.dashboardData.campaignCount
  );


  const credits = useSelector((state) => state.dashboard.dashboardData.credits);
  const [showAlert, setShowAlert] = useState(false);
  const [expireAlert, setExpireAlert] = useState(false);
  const formattedCredits = formatNumber(credits);
  const headers = {
    Authorization: `Bearer ${getCookie("bearerToken")}`,
  };

  // Logout Handler
  const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure to sign out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const bearerToken = getCookie("bearerToken");
          await axios.post(
            `${baseURL}auth/logOut`, // Replace with the correct logout API endpoint
            {},
            {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
              },
            }
          );
          clearAuthentication(); // Clear cookies and localStorage
          toast.success("Logged out successfully", {
            position: "bottom-right",
          });
          navigate("/login"); // Redirect to login page
        } catch (error) {
          toast.error("Failed to log out. Please try again.", {
            position: "bottom-right",
          });
        }
      }
    });
  };

  // Fetch data only if Redux state is empty
  useEffect(() => {
    if (!userData || Object.keys(userData).length === 0) {
      dispatch(fetchDashboardData({ source: ["user"], reload: false }));
    } else {
      // If user data is already in the state, no need to fetch it again
      if (userData.status !== "Active") setShowAlert(true);
      if (new Date(userData.planExpiresOn) < new Date()) setExpireAlert(true);
    }
  }, [dispatch, userData]);

  return (
    <>
      <Navbar expand="lg" className="bg-white sticky-top sb-shadow-bottom subnav">
        <Container fluid className="py-0">
          <Navbar.Brand href="/">
            <img
              src={logo}// Ensure your logo is in public folder
              alt="Website Logo"
              width="200" // Adjust size as needed
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Row className="w-100 me-3">
            <Col className="w-100 d-flex justify-content-end">
              <div
                className={`text-orange align-items-center header-campaign-summary ${location.pathname === "/account/dashboard"
                  ? "d-none"
                  : "d-none d-lg-flex"
                  }`}
              >
                <Link
                  to="/account/campaigns"
                  className="text-decoration-none text-muted campaign-subnav"
                >
                  <div
                    className="d-flex align-items-center sb-shaow-rightd  pe-3 "
                    onClick={() => navigate("/account/campaigns")}
                  >
                    <CiBullhorn className="me-2  text-orange" size={"30px"} />
                    <div className="d-flex flex-column ">
                      <div className="text-center fw-bold">{campaignCount}</div>
                      <span className="text-muted">Campaigns</span>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/account/campaigns"
                  className="text-decoration-none text-muted campaign-subnav"
                >
                  <div
                    className="d-flex align-items-center sb-shaow-rightd ps-3 pe-3 "
                    onClick={() => navigate("/account/campaigns")}
                  >
                    <MdCheckCircle className="me-2 text-orange" size={"20px"} />
                    <div className="d-flex flex-column">
                      <span className=" fw-bold">{CompletecampaignData}</span>
                      <span className="text-muted">Done</span>
                    </div>
                  </div>
                </Link>

                <Link
                  to="/account/campaigns"
                  className="text-decoration-none text-muted campaign-subnav"
                >
                  <div
                    className="d-flex align-items-center ms-3 sb-shaow-rightd pe-3 "
                    onClick={() => navigate("/account/campaigns")}
                  >
                    <MdSync className="me-2 text-orange" size={"20px"} />
                    <div className="d-flex flex-column">
                      <span className="fw-bold">{PendingcampaignData}</span>
                      <span className="text-muted">Active</span>
                    </div>
                  </div>
                </Link>

                <Link
                  to="/account/campaigns"
                  className="text-decoration-none text-muted campaign-subnav"
                >
                  <div
                    className="d-flex align-items-center ms-3 pe-3"
                    onClick={() => navigate("/account/campaigns")}
                  >

                    <FiPlusCircle className="me-2 text-orange" size={"20px"} />
                    <div className="d-flex flex-column">
                      <span className="fw-bold ">{NewcampaignData}</span>
                      <span>New</span>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown
                title={
                  <span className="">
                    <PiCoinsLight className="me-1 sq-db-icon" size={"30px"} />
                    {new Date(userData.planExpiresOn) < new Date() ? 0 : `${formattedCredits}`}
                  </span>
                }
                className="me-3 d-flex align-items-center"
              >
                {new Date(userData.planExpiresOn) < new Date() ? (
                  <NavDropdown.Item
                    onClick={() => navigate("/account/billing/plans")}
                  >
                    Upgrade Plan
                  </NavDropdown.Item>
                ) : (
                  <NavDropdown.Item
                    onClick={() => navigate("/account/addcredits")}
                  >
                    Add Credits
                  </NavDropdown.Item>
                )}
              </NavDropdown>
              <div className="d-flex align-items-center user-info" onClick={handleLogOut}>
                <span className="user-name me-1 text-capitalize">{`${userData?.firstName} ${userData?.lastName}` || "User"}</span>
                <MdOutlinePowerSettingsNew size={24} className="logout-icon ms-1 text-secondary" />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </>
  );
};


export default SubNav;