import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Set a cookie with a specific key and value
export const setCookie = (key, value) => {
  cookie.set(key, value, { expires: 1 }); // Cookie expires in 1 day
};

// Remove a cookie by key
export const removeCookie = (key) => {
  cookie.remove(key);
};

// Get a cookie value by key
export const getCookie = (key) => {
  return cookie.get(key);
};

// Set the authentication details including the bearer token and login time
export const setAuthentication = (bearerToken) => {
  setCookie("bearerToken", bearerToken);
  localStorage.setItem("token", bearerToken);
  localStorage.setItem("isSignedIn", "true");
  localStorage.setItem("loginTime", new Date().getTime());
};

export const clearAuthentication = () => {
  removeCookie("bearerToken");
  localStorage.removeItem("token");
  localStorage.removeItem("isSignedIn");
  localStorage.removeItem("loginTime");
};

// Check if the user is logged in and if the token has expired
export const isLogin = async (navigate) => {
  const bearerToken = localStorage.getItem("token");
  if (!bearerToken) {
    clearAuthentication();
    // navigate("/login");
    return false;
  }

  try {
    const decodedToken = jwtDecode(bearerToken);
    const tokenExpiryTime = decodedToken.exp * 1000; // Convert to milliseconds
    const currentTime = new Date().getTime();

    if (currentTime > tokenExpiryTime) {
      clearAuthentication();
      // navigate("/login");
      return false;
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    clearAuthentication();
    // navigate("/login");
    return false;
  }

  return true;
};


export const logOut = () => {
  clearAuthentication();
};

export const getUserIdFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userId;
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};
