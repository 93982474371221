import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { baseURL } from "../../../utils/constant";
import { getCookie } from "../../../utils/auth";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchDashboardData } from "../../../redux/dashboardSlice";

const StripeSuccess = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}payment/saveStripeLogsByUserId?session_id=${sessionId}`,
          {
            // headers: {
            //   Authorization: `Bearer ${getCookie("bearerToken")}`,
            //   session_id: sessionId,
            // },
          }
        );
        //available amount = response.data.userCredits
        // update the credit from state accordingly
        //in response data thir is url which needs to be shown in iframe
        const url = response.data.receipt_url;
    
        setPaymentDetails(response.data);
        dispatch(fetchDashboardData({ source: ["allcount","user" , "campaign", "payment", "lead", "usage", "credits", "campaignCount", "paymentCount", "leadCount", "verifiedLeads", "notVerifiedLeads","pieChart"], reload: false}));

      } catch (err) {
        setError("Failed to fetch payment details. Please try again later.");
      }
    };

    if (sessionId) {
      fetchPaymentDetails();
    }
  }, [sessionId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!paymentDetails) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-cenetr">
        Loading payment details...
      </div>
    );
  }

  return (
    <div className="vh-100">
      {paymentDetails.message === "Payment successful." && (
        <>
          <div className="vh-100 d-flex justify-content-center align-items-center flex-column">
            <DotLottieReact
              src="https://lottie.host/15ea6052-3795-4ca0-9483-e7f0d54682fa/47xHs72VPc.lottie"
              autoplay
              style={{ width: "200px", height: "200px" }} // Set width & height here
            />
            <div>
              <p className="text-primary fs-4">Payment Successfull</p>
            </div>
            <div>
              <div className="d-flex justify-content-center">
                <Button
                  className="me-2"
                  variant="primary"
                  onClick={() =>
                    window.open(paymentDetails.receipt_url, "_blank")
                  }
                >
                  View Invoice
                </Button>
                <Button
                  variant="primary"
                  onClick={() => navigate("/account/dashboard")}
                >
                  Go to Dashboard
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StripeSuccess;
