import React from 'react'
import { baseURL } from '../utils/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';


const EmailAlert = () => {

  const handleResendVerificationLink = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${baseURL}auth/sendVerificationLink`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Verification email sent successfully!", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Failed to send verification email. Please try again.", {
        position: "bottom-right",
      });
    }
  };


  return (
    <Container fluid className="mt-3">
      <Alert variant="warning">
        <Row>
          <Col>
            <p className="mb-0">
              Your email address is not verified. Please check your inbox or
              <a href="#" onClick={handleResendVerificationLink} className='fw-bold ms-1'>
                click here
              </a>
            </p>
          </Col>
        </Row>
      </Alert>
    </Container>
  )
}

export default EmailAlert
