import React from "react";
import { Col } from "react-bootstrap";
import { PiCoinsThin } from "react-icons/pi"; // Adjust import based on your setup

const formatValue = (value) => {
  if (value < 1000) {
    return value.toString(); // Keep it as is for numbers below 1000
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K"; // Format to 'K'
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Format to 'M'
  } else {
    return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B"; // Format to 'B'
  }
};
const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}


const DashboardCredits = ({ dashboardData }) => (
  <div className="p-3 card">
    <div className="card-body p-2">
      <div className="row">
        <div className="col-8">
          <div className="numbers">
            <p className="text-sm mb-0 text-uppercase font-weight-bold">
              Credits
            </p>
            <h5 className="font-weight-bolder">
            
              {getFormattedDate(dashboardData.user.planExpiresOn) < getFormattedDate(new Date()) || dashboardData.user.planExpiresOn < new Date() ? 0 : formatValue(dashboardData.credits)}
            </h5>
            <p className="mb-0">
              {/* <span className="text-success text-sm font-weight-bolder">+55%</span>
                          since plan purchased */}
            </p>
          </div>
        </div>
        <div className="col-4 text-end">
          <div className="icon icon-shape bg-primary shadow-primary text-center rounded-circle">
            <PiCoinsThin
              style={{ fontSize: "24px", opacity: 0.9 }}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DashboardCredits;
