import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie, getUserIdFromToken } from "../../../utils/auth";
// import { fetchCampaigns } from "../../../redux/campaignSlice";
import Swal from "sweetalert2";
import { Col, Container, Row, Button, Dropdown, Form } from "react-bootstrap";
// import { GoPlus } from "react-icons/go";
import DataTable from "react-data-table-component";
import Loading from "../../../assets/img/dashbord/loading.gif";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { GoSearch, GoPlus } from "react-icons/go";
import { LuRefreshCcw } from "react-icons/lu";
import { fetchDashboardData } from "../../../redux/dashboardSlice";
import {editCampaign, deleteCampaign} from '../../../redux/dashboardSlice'
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import format from "date-fns/format";
import { Sticky } from "react-bootstrap-icons";

const PastCampaignRedux = () => {
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10); // Default entries per page
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateRange, setDateRange] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();



  const { campaignData: campaignData, isLoading } = useSelector(
    (state) => state.dashboard
  );




  const filteredData = useMemo(() => {
    if (!campaignData) return [];
    let filtered = campaignData;

    // Filter by search text (Campaign Name only)
    if (searchText.trim()) {
      const searchTerm = searchText.toLowerCase();
      filtered = filtered.filter((row) =>
        row.campaignName.toLowerCase().includes(searchTerm)
      );
    }

    // Filter by status
    if (statusFilter !== "All") {
      filtered = filtered.filter((row) => row.status === statusFilter);
    }

    // Filter by date
    // if (dateFilter) {
    //   // Ensure the date format is comparable
    //   const filterDate = new Date(dateFilter).toLocaleDateString();
    //   filtered = filtered.filter(
    //     (row) => new Date(row.createdAt).toLocaleDateString() === filterDate
    //   );
    // }

    if (dateRange && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter((row) => {
        const createdAt = new Date(row.createdAt); // Assuming 'createdAt' is the field for the date
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    return filtered;
  }, [campaignData, searchText, statusFilter, dateRange]);

  const handleRefresh = () => {
    dispatch(fetchDashboardData({ source: ["campaign"], reload: true})); // r campaign data
    console.log("Campaign Data :", campaignData);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this campaign?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            eventType: "deleteCampaignByCampaignId",
            id: id,
          };

          const response = await axios.delete(`${baseURL}update/deleteModel`, {
            headers: {
              Authorization: `Bearer ${getCookie("bearerToken")}`,
              "Content-Type": "application/json",
              userId: getUserIdFromToken(getCookie("bearerToken")),
            },
            data: payload,
          });

          if (response.status === 200) {
            dispatch(deleteCampaign(id));
            dispatch(fetchDashboardData({ source: ["campaign"], reload: true}));
            Swal.fire("Deleted!", "Campaign has been deleted.", "success");
          } else {
            Swal.fire(
              "Error",
              "Failed to delete campaign. Please try again.",
              "error"
            );
          }
        } catch (error) {
          console.error("Error deleting campaign:", error);
          Swal.fire(
            "Error",
            "Failed to delete campaign. Please try again.",
            "error"
          );
        }
      }
    });
  };


  const handleEdit = async (id, campaignName) => {
    const newCampaignName = await Swal.fire({
      title: `Do you want to rename <b>${campaignName.toUpperCase()}</b>?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f76a28",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      input: "text",
      inputLabel: "New Campaign Name",
      inputPlaceholder: "Enter the new name",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter a campaign name!";
        }
      },
    }).then((result) => {
      return result.value;
    });

    if (newCampaignName) {
      try {
        const payload = {
          campaignName: newCampaignName,
          eventType: "editCampaignByCampaignId",
          id: id,
        };

        const response = await axios.put(
          `${baseURL}update/updateModel`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${getCookie("bearerToken")}`,
              "Content-Type": "application/json",
              id: id,
              userId: getUserIdFromToken(getCookie("bearerToken")),
            },
          }
        );

        Swal.fire({
          title: "Success!",
          text: `Campaign renamed to "${newCampaignName}" successfully.`,
          icon: "success",
          confirmButtonColor: "#f76a28",
        });

        dispatch(editCampaign({ id, campaignName: newCampaignName }));
        dispatch(fetchDashboardData({ source: ["campaign"], reload: true}));
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Failed to rename the campaign. Please try again later.",
          icon: "error",
          confirmButtonColor: "#f76a28",
        });
      }
    }
  };


  // Dropdown Action Component

  // Custom Toggle for Dropdown
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ display: "inline-block" }}
    >
      {children}
    </div>
  ));

  // Column definitions for DataTable
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + (index + 1), // Adjust Sr. No based on pagination
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) =>
        row && row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB")
          : "-",
      sortable: true,
    },
    {
      name: "Campaign Name",
      selector: (row) =>
        row && row.campaignName
          ? row.campaignName.charAt(0).toUpperCase() + row.campaignName.slice(1)
          : "No Campaign Data Found",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <span className="status-badge">
          <span
            className={`badge1 ${row?.status === "New"
                ? "badge badge-info"
                : row?.status === "Pending"
                  ? "badge badge-warning"
                  : row?.status === "Complete"
                    ? "badge badge-success"
                    : ""
              }`}
          >
            {row?.status === "New"
              ? "Missing File"
              : row?.status === "Pending"
                ? "Started"
                : row?.status === "Complete"
                  ? "Completed"
                  : "-"}
          </span>
        </span>
      ),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row, index) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <BiDotsHorizontalRounded size={24} className="action-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            <Dropdown.Item
              onClick={() => {
                if (row.status === "New") {
                  navigate(`/account/uploadcsv/${row._id}`);
                } else if (row.status === "Pending") {
                  navigate(`/account/stepprogressbar/${row._id}`);
                } else {
                  navigate(`/account/completeStatus/${row._id}`);
                }
              }}
            >
              <MdOutlineRemoveRedEye className="me-2" /> View
            </Dropdown.Item>

            <Dropdown.Item
              className="text-orange"
              onClick={() => handleEdit(row._id, row.campaignName)} // Updated Edit action
            >
              <AiOutlineEdit className="me-2" /> Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              onClick={() => handleDelete(row._id)}
            >
              <AiOutlineDelete className="me-2" /> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const statusMap = {
    New: "Missing File",
    Pending: "Started",
    Complete: "Completed",
  };

  return (
    <>
      <div className="campaign-list">
        <div className="container-fluid my-4">
          {isLoading ? (
            <div className="loading-container">
            <img src={Loading} alt="Loading" width={25} />
          </div>
          
          ) : (
            <>
              <div className="card">
                {/* Card Header */}
                <div className="card-header pb-0">
                  <div className="d-lg-flex">
                    <div>
                      <h5 className="mb-0">
                        Campaigns
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="refresh-tooltip">Refresh</Tooltip>
                          }
                        >
                          <span className="m-2" onClick={handleRefresh}>
                            <LuRefreshCcw
                              size={20}
                              className="me-1 sq-db-icon"
                            />
                          </span>
                        </OverlayTrigger>
                      </h5>

                      <p className="text-sm mb-0">All campaign's entries</p>
                    </div>
                    <div className="ms-auto my-auto mt-lg-0 mt-4">
                      <div className="ms-auto my-auto">
                        <div className="d-flex justify-content-end align-items-center gap-3 campaign-controls">
                          {/* Search Bar */}
                          <input
                            className="custom-search-bar"
                            placeholder="Search Campaigns..."
                            type="text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />

                          {/* Status Dropdown */}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              id="status-filter-dropdown"
                              className="custom-dropdown-toggle"
                            >
                              {statusFilter === "All" ? "Select Status" : statusMap[statusFilter] || statusFilter}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setStatusFilter("All")}>All</Dropdown.Item>
                              {Object.entries(statusMap).map(([key, label]) => (
                                <Dropdown.Item key={key} onClick={() => setStatusFilter(key)}>
                                  {label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* Date Range Picker */}
                          <DateRangePicker
                            placement="bottomEnd"
                            ISOWeek
                            showOneCalendar
                            format="dd MMM yyyy"
                            value={dateRange}
                            onChange={(value) => setDateRange(value)}
                            renderValue={([start, end]) => {
                              return (
                                format(start, "EEE, d MMM") +
                                " - " +
                                format(end, "EEE, d MMM")
                              );
                            }}
                            className="custom-date-range"
                            placeholder="Select Date Range"
                          />

                          {/* New Campaign Button */}
                          <Button
                            className="btn bg-gradient-primary btn-sm"
                            onClick={() => navigate("/account/createcampaign")}
                          >
                            <span className="d-flex align-items-center">
                              <AiOutlinePlus className="me-1" size={"20px"} />
                              <span className="fs-6">New Campaign</span>
                            </span>
                          </Button>
                        </div>

                        {/* Import Modal */}
                        <div
                          className="modal fade"
                          id="import"
                          tabIndex="-1"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog mt-lg-10">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="ModalLabel">
                                  Import CSV
                                </h5>
                                <i className="fas fa-upload ms-3"></i>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <p>You can browse your computer for a file.</p>
                                <input
                                  type="text"
                                  placeholder="Browse file..."
                                  className="form-control mb-3"
                                />
                                <div className="form-check is-filled">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="importCheck"
                                    defaultChecked
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="importCheck"
                                  >
                                    I accept the terms and conditions
                                  </label>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn bg-gradient-secondary btn-sm"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  className="btn bg-gradient-primary btn-sm"
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Card Body */}
                <div className="card-body px-0 pb-0">
                  <div className="table-responsive">
                    <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                      {/* DataTable Section */}
                      <div
                        className="table-container"
                        style={{ position: "relative" }}
                      >
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          fixedHeader
                          fixedHeaderScrollHeight="500px"
                          onChangePage={(page) => setCurrentPage(page)}
                          paginationPerPage={entriesPerPage}
                          noHeader={false}
                          persistTableHead={true} // Keeps header fixed across pages
                          className="table align-items-center mb-0"
                          noDataComponent={<div>No Campaign Found</div>}
                          customStyles={{
                            headCells: {
                              style: {
                                position: "sticky",
                                top: 0,
                                fontWeight: "bold",
                                fontSize: "14px",
                                zIndex: 100,
                                backgroundColor: "#EBE7FC",
                                fontFamily: "Proxima Nova",
                              },
                            },
                          }}
                        />
                        {/* Footer Section */}
                        <div className="dataTable-bottom">
                          <div className="dataTable-info">
                            Showing {filteredData.length} entries
                          </div>
                          <nav className="dataTable-pagination">
                            <ul className="dataTable-pagination-list"></ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default PastCampaignRedux;
