import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Scrollbar from "smooth-scrollbar";

import AppRoutes from "./routes/AppRoutes";
import "./index.css";
import "./legacy.css";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import TokenValidator from "./components/TokenValidator";
import ScrollToTop from "./components/ScrollToTop";

const AppWithAuth = () => {
  /**
   * Higher-order component that checks authentication status
   * before rendering the main app routes.
   *
   * This component is used to delay rendering of the app until
   * the authentication status has been checked.
   *
   * @returns {JSX.Element} The app routes component.
   */
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
    setIsAuthChecked(true);
  }, []);

  if (!isAuthChecked) return null; // Delay rendering until authentication is checked

  return (
    <BrowserRouter>
    <ScrollToTop />
      <TokenValidator />
      <AppRoutes
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
    </BrowserRouter>
  );
};

const App = () => {
  useEffect(() => {
    const options = {
      damping: 0.1, // Smoothness of the scrolling
    };

    // Apply smooth scrollbar only to the content area (exclude sidebar)
    const contentElement = document.getElementById("Container");
    if (contentElement) {
      const scrollbarInstance = Scrollbar.init(contentElement, options);

      // Cleanup on unmount
      return () => {
        scrollbarInstance.destroy();
      };
    }
  }, []);

  return <AppWithAuth />;
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <ToastContainer />
      </PersistGate>
    </Provider>
  </>
);
