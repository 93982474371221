import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/constant";
import React, { useState, useEffect } from "react";
import logo from "../../assets/img/images/logo/newbluelogo.png";
import { IoMailOutline } from "react-icons/io5";
import { getCookie, isLogin, setAuthentication } from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MdEmail } from "react-icons/md";

export default function Login({ setisAuthenticated }) {
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin(navigate)) {
        navigate("/");
      }
    };
    authenticate();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
    setIsButtonDisabled(!email || !password);
  };

  const handleSubmit = async (e) => {
    // const token = localStorage.getItem("token");
    e.preventDefault();
    try {
      const payload = { email };
      const response = await axios.post(
        `${baseURL}auth/resetPassword`,
        payload,
        {
          headers: { ...headers },
        }
      );
      const { message } = response.data;
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate(`/login`);
    } catch (error) {
      toast(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <Container fluid className="p-0 m-0 overflow-hidden">
        <Row>
          <Col lg={12} className="mx-auto">
            <div className="d-flex align-items-center justify-content-center min-vh-100 bg-login bg-orange-gradient">
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={6}>
                    <div className="card border-0 shadow bg-white rounded-5">
                      <div className="card-body p-5">
                      <Container className="d-flex justify-content-center align-items-center">
                      <Link to="/" className="text-center">
                        <img src={logo} alt="Logo" className="img-fluid w-50" />
                      </Link>
                    </Container>
                        <h4 className="fw-bold text-strat mb-4">
                          Forgot Password
                        </h4>

                        <Form onSubmit={handleSubmit}>
                          <Form.Group className="mb-4">
                            <Form.Label className="label-email-class px-2 position-relative">
                              Email
                            </Form.Label>
                            <div className="input-group">
                              <span className="input-group-text ">
                                <MdEmail className="text-muted" />
                              </span>
                              <Form.Control
                                type="email"
                                className="rounded-end form-control-sm py-3 border-start-0 input-orange"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Form.Group>

                          <div className="d-grid gap-2 mb-4">
                            <Button
                              type="submit"
                              className="btn bg-gradient-warning rounded-3 py-3 border-0"
                              disabled={isLoading}
                            >
                              {isLoading
                                ? "Linking in..."
                                : "Send Password Reset Link"}
                            </Button>
                          </div>
                        </Form>

                        <div className="text-center">
                          <Link
                            to="/login"
                            className="text-decoration-none text-orange"
                          >
                            Back to Sign In
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
